import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

import './TitledBlock.css';
export default function(props) {
  return (
    <Grid.Row className='TitledBlock content-margin' style={{ ...props.style }}>
      <Grid.Column>
        { props.title && <Header color='blue'>{props.title}</Header> }
        {props.children}
      </Grid.Column>
    </Grid.Row>
  );
}