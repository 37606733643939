import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { Grid, Loader, Button, Modal, List, Header, Dimmer, Message, Label, Menu, Input } from 'semantic-ui-react';
import ContentHeader from '../components/ContentHeader';

import { formatCurrency } from 'unid-core/lib/utils/formatter';
import { removeAccents } from 'unid-core/lib/utils/string';
import IDs from 'unid-core/lib/utils/IDs';

import { createProposalTable } from './utils/proposal';

export default class Orders extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 0,
      searchText: '',
      search: null,
      list: null,
      removeOrder: null,
      fetchingFullOrder: false
    };
  }

  removeOrderConfirm(order) {
    this.setState({ removeOrder: order });
  }

  async removeOrder() {
    try {
      this.setState({ removeOrder: null });
      await window.cs_delete(`/orders/${this.state.removeOrder.id}`);
      this.componentDidMount();
    } catch(err) {
      console.error(err);
    }
  }

  ordersPerPage = 16;

  ordersList() {
    let orders = this.state.search || this.state.list;
    let list = [];

    for (let i = this.state.page * this.ordersPerPage; i < orders.length; i++) {
      const e = orders[i];
      if (list.length >= this.ordersPerPage) break;

      let advertiser = null;
      if (e.advertiser_name) advertiser = {
        name: e.advertiser_name,
        identity: e.advertiser_identity
      };

      list.push(
        <List.Item key={i}>
          <List.Content floated='left' style={{marginRight:4}}>
            <a href={`/pedidos/${e.id}/PI`} target='_blank' rel='noopener noreferrer' style={{fontFamily:'Cutive Mono',fontSize:16,fontWeight:800}}>#{e.id}</a>
          </List.Content>

          <List.Content floated='right'>
            <Header as='h1' size='small' color='olive' style={{display:'inline-block',marginRight:18}}>{formatCurrency(e.total)}</Header>
            { this.props.type === IDs.PI && <Link to={`/pedidos/${e.id}`}><Button color='olive' size='small' icon='setting'/></Link> }
            { this.props.type === IDs.Proposal && <Button disabled={e.id<1246} color='olive' size='small' icon='table' onClick={()=>this.createProposal(e)}/> }
            { (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid || (this.props.userData.role === IDs.Advertiser && this.props.type === IDs.Proposal)) && <Link to={`/compra-online/${e.id}`}><Button primary size='small' icon='pencil'/></Link> }
            { this.props.userData.role === IDs.Admin && <Button negative size='small' icon='trash' onClick={() => this.removeOrderConfirm(e) }/> }
          </List.Content>

          <List.Content>
            <List.Header>- {advertiser?advertiser.name||'?':(e.additional_data.advertiser_name||'Anunciante não cadastrado').toUpperCase()}{advertiser?(advertiser.identity?' - '+advertiser.identity:null):null}</List.Header>
            <List.Description style={{fontSize:11,marginTop:2}}>
              { this.props.type===IDs.PI && !e.signature && e.id > 2005 &&
                <Label size='mini' color='orange' style={{padding:4,marginRight:4}}>Aceite pendente</Label>
              }
              { this.props.type===IDs.PI && e.signature && e.id > 2005 &&
                <Label size='mini' color='green' style={{padding:4,marginRight:4}}>{e.signature==='byhand'?'Termo aceito':'Termo assinado'}</Label>
              }
              Criado em {moment(e.date).format('DD/MM/YY')}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    }

    return list;
  }

  pagination() {
    let orders = this.state.search || this.state.list;
    const pageCount = Math.ceil(orders.length / this.ordersPerPage);

    if (pageCount <= 1) return null;

    let startIdx = 0;
    let endIdx = pageCount;

    if (pageCount > 7) {
      startIdx = this.state.page - 3;
      endIdx = this.state.page + 4;

      const left_factor = this.state.page - 3;
      const right_factor = (this.state.page + 4) - pageCount;

      if (left_factor < 0) {
        startIdx = 0;
        endIdx += Math.abs(left_factor);
      }

      if (right_factor > 0) {
        startIdx -= right_factor;
        endIdx -= right_factor;
      }
    }

    let pages = [];
    if (startIdx !== 0)
      pages.push(<Menu.Item key={0} onClick={()=>this.setState({ page: 0 })}>&lt;&lt; Início</Menu.Item>);

    for (let i = startIdx; i < endIdx; i++) {
      pages.push(<Menu.Item key={i} active={i === this.state.page} onClick={()=>this.setState({ page: i })}>{i+1}</Menu.Item>);
    }

    if (endIdx < pageCount)
      pages.push(<Menu.Item key={pageCount-1} onClick={()=>this.setState({ page: pageCount-1 })}>Fim &gt;&gt;</Menu.Item>);

    return (
      <Menu pagination size='mini' >
        { pages }
      </Menu>
    );
  }

  doSearchKey(key, value, e) {
    if (!value || !e[key]) return false;
    return removeAccents(e[key].toString()).toLowerCase().indexOf(removeAccents(value).toLowerCase()) !== -1;
  }

  doSearch(value) {
    this.setState({ searchText: value, page: 0 });
    
    if (!value) {
      this.setState({ search: null });
      return;
    }

    let fine_results = [];
    let sparse_results = [];
    
    for (let order of this.state.list) {
      if (this.doSearchKey('id', value, order))
        fine_results.push(order);
      if (this.doSearchKey('identifier', value, order))
        fine_results.push(order);
      if (order.advertiser_id && this.doSearchKey('advertiser_name', value, order))
        fine_results.push(order);
      if (!order.advertiser_id && this.doSearchKey('advertiser_name', value, order.additional_data))
        fine_results.push(order);
      if (order.advertiser_id && this.doSearchKey('advertiser_identity', value, order))
        sparse_results.push(order);
    }
   
    let allResults = fine_results.concat(sparse_results);
    
    function isPresentInArray(e, beforePos) {
      for (let i = 0; i < beforePos; i++) {
        if (allResults[i].id === e.id) return true;
      }
      return false;
    }

    for (let i = allResults.length -1; i >= 0; i--) {
      if (isPresentInArray(allResults[i], i)) allResults.splice(i, 1);
    }

    this.setState({ search: allResults });
  }
  
  async createProposal(e) {
    this.setState({ fetchingFullOrder: true });
    await createProposalTable(e);
    this.setState({ fetchingFullOrder: false });
  }

  async componentDidMount() { 
    try {
      const res = await window.cs_get('/orders', { type: this.props.type });
      for (let order of res.data) order.additional_data = JSON.parse(order.additional_data);
      this.setState({ list: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} path={[this.props.type===IDs.PI?'Pedidos':'Propostas']}/>

        { !this.state.list &&
          <Loader active={true} size='large' style={{marginTop:200}}>Carregando...</Loader>
        }

        { this.state.fetchingFullOrder &&
          <Dimmer inverted active>
            <Loader active size='large' style={{marginTop:200}}>Gerando tabela...</Loader>
          </Dimmer>
        }

        { this.state.list && this.state.list.length === 0 &&
          <Grid.Row className='content-margin'>
            <Grid.Column>
              <Message warning>
                <Message.Header>Nada encontrado!</Message.Header>
                <p>Você não possui {this.props.type===IDs.PI?'nenhum pedido':'nenhuma proposta'} para exibição.</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list && this.state.list.length > 0 &&
          <Grid.Row className='content-margin' style={{marginBottom:0}}>
            <Grid.Column>
              <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch(value)} />
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list &&
          <Grid.Row className='content-margin'>
            <Grid.Column>
              <List divided relaxed='very'>
                { this.ordersList() }
              </List>
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list &&
          <Grid.Row style={{paddingTop:0,marginTop:0}}>
            <Grid.Column style={{textAlign:'center'}}>
              { this.pagination() }
            </Grid.Column>
          </Grid.Row>
        }

        <Modal closeOnDimmerClick={false} size={'tiny'} open={!!this.state.removeOrder}>
          <Modal.Header>
            Removendo {this.props.type===IDs.PI?'pedido':'proposta'} #{this.state.removeOrder && this.state.removeOrder.id}
          </Modal.Header>
          <Modal.Content>
            { this.state.removeOrder && this.state.removeOrder.advertiser &&
              <p>Você tem certeza que deseja remover {this.props.type===IDs.PI?'este pedido':'esta proposta'} de <b>{this.state.removeOrder.advertiser.name}</b>?</p>
            }

            { this.state.removeOrder && !this.state.removeOrder.advertiser &&
              <p>Você tem certeza que deseja remover {this.props.type===IDs.PI?'este pedido':'esta proposta'}?</p>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Não' onClick={()=>this.setState({ removeOrder: null})} />
            <Button positive icon='checkmark' labelPosition='right' content='Sim' onClick={this.removeOrder.bind(this)}/>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}