import React, { Component } from 'react';

import axios from 'axios';
import { conformToMask } from 'react-text-mask';

import { Modal, Form, Input, Dropdown, Button, Divider, List, Header } from 'semantic-ui-react';

import { formatNumber } from 'unid-core/lib/utils/formatter';
import { segments } from 'unid-core/lib/utils/segments';

export default class EntityEditor extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.cleanState();
  }

  typeStrToNumber() {
    switch(this.state.entityType) {
      case 'agency': return 2;
      case 'advertiser': return 3;
      case 'network': return 0;
      case 'theater': return 1;
      default: return -1;
    }
  }

  stateFromUf(uf) {
    if      (uf === "AC") return "Acre";
    else if (uf === "AL") return "Alagoas";
    else if (uf === "AP") return "Amapá";
    else if (uf === "AM") return "Amazonas";
    else if (uf === "BA") return "Bahia";
    else if (uf === "CE") return "Ceará";
    else if (uf === "DF") return "Distrito Federal";
    else if (uf === "ES") return "Espírito Santo";
    else if (uf === "GO") return "Goiás";
    else if (uf === "MA") return "Maranhão";
    else if (uf === "MT") return "Mato Grosso";
    else if (uf === "MS") return "Mato Grosso do Sul";
    else if (uf === "MG") return "Minas Gerais";
    else if (uf === "PA") return "Pará";
    else if (uf === "PB") return "Paraíba";
    else if (uf === "PR") return "Paraná";
    else if (uf === "PE") return "Pernambuco";
    else if (uf === "PI") return "Piauí";
    else if (uf === "RJ") return "Rio de Janeiro";
    else if (uf === "RN") return "Rio Grande do Norte";
    else if (uf === "RS") return "Rio Grande do Sul";
    else if (uf === "RO") return "Rondônia";
    else if (uf === "RR") return "Roraima";
    else if (uf === "SC") return "Santa Catarina";
    else if (uf === "SP") return "São Paulo";
    else if (uf === "SE") return "Sergipe";
    else if (uf === "TO") return "Tocantins";
    else				         return "";
  }

  hasTag(tag, tags) {
    for (let _tag of tags) if (_tag.value === tag) return true;
    return false;
  }

  cleanState(entity) {
    let state = {
      entityType: '',
      loading: false,
      mainModalOpen: false,
      contactModalOpen: false,
      editingEntity: null,
      values: { active: 1, name: '', title: '', identity: '', register: '', website: '', contacts: [], zip: '', address: '', number: '', complement: '', district: '', city: '', ibge: '', state: '', uf: '', country: '', rel_id: null, additional_data: { segments: [] } },
      editingContact: -1,
      contact: '',
      contactType: '',
      contactMasked: '',
      contactTags: [
        { key: 'contato', text: 'Contato', value: 'Contato' },
        { key: 'faturamento', text: 'Faturamento', value: 'Faturamento' }
      ],
      contactSelectedTags: []
    };

    if (entity) {
      for (let contact of entity.contacts) {
        for (let tag of contact.tags) {
          if (!this.hasTag(tag, state.contactTags))
            state.contactTags.push({
              key: tag.toLowerCase(),
              text: tag,
              value: tag
            });
        }
      }
    }

    return state;
  }

  agencies() {
    let ret = [{key:'none',text:'Nenhuma',value:null}];

    for (let agency of this.props.agencies) {
      ret.push({
        key: agency.id,
        text: agency.name + ' - ' + agency.identity,
        value: agency.id,
        content: <Header size='tiny' content={agency.name} subheader={agency.identity} />
      });
    }

    return ret;
  }

  networks() {
    let ret = [{key:'none',text:'Nenhuma',value:null}];

    for (let network of this.props.networks) {
      ret.push({
        key: network.id,
        text: network.name + ' - ' + network.identity,
        value: network.id,
        content: <Header size='tiny' content={network.name} subheader={network.identity} />
      });
    }

    return ret;
  }

  segments() {
    return segments.map((e) => {
      return {
        key: e.id,
        value: e.id,
        text: e.name
      };
    });
  }

  handleInputChange(e, { name, value }) {
    if (name === 'active') {
      this.setState({ values: { ...this.state.values, active: value ? 1 : 0 } });
      return;
    }

    if (name === 'shopping' || name === 'public' || name === 'video_wall_count' || name === 'bomboniere_count' || name === 'foyer_count' || name === 'cut' || name === 'segments') {
      if (name === 'cut') {
        value = (parseInt(value.replace(/,/g,''), 10)||0) / 100;
        if (value < 0) value = 0;
        if (value > 100) value = 100;
      } else if (name !== 'shopping' && name !== 'segments') {
        value = Number(value);
        if (isNaN(value)) value = 0;
      }
      this.setState({ values: { ...this.state.values, additional_data: { ...this.state.values.additional_data, [name]: value }}});
      return;
    }

    if (name === 'identity') {
      const cleanValue = value.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
      if (cleanValue.length > 15) return;

      let mask = [/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/];
      if (cleanValue.length > 11) mask = [/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/];
      const masked = conformToMask(value, mask, { guide: false });
      value = masked.conformedValue;
    }

    if (name === 'zip') {
      const masked = conformToMask(value, [/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/], { guide: false });
      value = masked.conformedValue;

      if (value.length === 9) {
        axios.get(`https://viacep.com.br/ws/${value.replace(/-/g,'')}/json/`).then((res) => {
          if (res.status === 200) {
            this.setState({
              values: {
                ...this.state.values,
                address: res.data.logradouro,
                number: '',
                district: res.data.bairro,
                complement: res.data.complemento,
                city: res.data.localidade,
                ibge: res.data.ibge,
                state: this.stateFromUf(res.data.uf),
                uf: res.data.uf,
                country: 'Brasil'
              }
            })
          }
        });
      }
    }

    if (name === 'name' || name === 'title') {
      value = value.toUpperCase();
    }

    this.setState({ values: { ...this.state.values, [name]: value }});
  }

  handleContactChange(e, { value }) {
    if (this.state.contactType === 'email') {
      this.setState({ contact: value, contactMasked: value });
      return;
    }

    const cleanValue = value.replace(/ |-|\(|\)/g, '');
    if (cleanValue.length > 11) return;

    let mask = [/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/];
    if (cleanValue.length > 10) mask = [/\d/,/\d/,' ',/\d/,' ',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/];
    const masked = conformToMask(cleanValue, mask, { guide: false });
    this.setState({ contact: cleanValue, contactMasked: masked.conformedValue });
  }

  handleNewtag(e, { value }) {
    this.setState({
      contactTags: [{ text: value, value }, ...this.state.contactTags ]
    });
  }

  handleTagChange(e, { value }) {
    this.setState({ contactSelectedTags: value });
  }

  newContact(type) {
    this.setState({ contactModalOpen: true, contact: '', contactType: type, contactMasked: '', contactSelectedTags: [] });
  }

  removeContact(i) {
    let newContacts = [ ...this.state.values.contacts ];
    newContacts.splice(i, 1);
    this.setState({ values: { ...this.state.values, contacts: newContacts }});
  }
  
  editContact(i) {
    const contact = this.state.values.contacts[i];
    this.setState({ contactModalOpen: true, editingContact: i, contact: '', contactType: contact.type, contactMasked: contact.value, contactSelectedTags: contact.tags });
  }

  saveContact() {
    let newContacts = [ ...this.state.values.contacts ];
    const newContact = { type: this.state.contactType, value: this.state.contactMasked, tags: this.state.contactSelectedTags };

    if (this.state.editingContact === -1)
      newContacts.push(newContact);
    else
      newContacts[this.state.editingContact] = newContact;

    this.setState({ editingContact: -1, contactModalOpen: false, values: { ...this.state.values, contacts: newContacts } });    
  }

  editEntity(entityType, entity) {
    const entityId = entity ? entity['id'] : null;
    const values = entity ? { ...entity } : this.cleanState().values;

    if (entity) {
      delete values['id'];
      delete values['type'];

      if (entityType === 'agency' && isNaN(values.additional_data['cut'])) {
        values.additional_data['cut'] = 20;
      }
    } else {
      values.additional_data = {};

      if (entityType === 'theater') {
        values.additional_data = {
          shopping: '',
          public: 0,
          video_wall_count: 0,
          bomboniere_count: 0,
          foyer_count: 0
        };
      } else if (entityType === 'agency') {
        values.additional_data = {
          cut: 20
        };
      };
    }
    
    this.setState({ ...this.cleanState(entity), entityType, mainModalOpen: true, editingEntity: entityId, values: values });
  }

  async save() {
    this.setState({ loading: true });

    if (this.state.editingEntity) {
      try {
        let obj = { type: this.typeStrToNumber(this.state.entityType), ...this.state.values };
        obj.contacts = JSON.stringify(obj.contacts);
        obj.additional_data = JSON.stringify(obj.additional_data);

        await window.cs_post(`/entities/${this.state.editingEntity}`, obj);
        this.setState({ mainModalOpen: false });
        this.props.onCloseModal();
      } catch (err) {
        this.setState({ loading: false });
      }
    } else {
      try {
        let obj = { type: this.typeStrToNumber(this.state.entityType), ...this.state.values };
        obj.contacts = JSON.stringify(obj.contacts);
        obj.additional_data = JSON.stringify(obj.additional_data);

        await window.cs_post('/entities', obj);
        this.setState({ mainModalOpen: false });
        this.props.onCloseModal();
      } catch (err) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    return (
      <div>
        <Modal size='small' closeOnDimmerClick={false} open={this.state.contactModalOpen} onClose={()=>this.setState({ contactModalOpen: false })}>
          <Modal.Header>{this.state.editingContact===-1?'Novo':'Editando'} {this.state.contactType==='email'?'Email':'Telefone'}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group widths='equal'>
                <Form.Field label={this.state.contactType==='email'?'Email':'Telefone'} placeholder={this.state.contactType==='email'?'email@email.com':'99 9999-9999'} control={Input} icon={this.state.contactType==='email'?'mail':'phone'} iconPosition='left' value={this.state.contactMasked} onChange={this.handleContactChange.bind(this)} />
                <Form.Field label='Tags' placeholder='Selecione ou crie uma tag' control={Dropdown} options={this.state.contactTags} value={this.state.contactSelectedTags} multiple selection search noResultsMessage='Nenhuma tag encontrada' allowAdditions additionLabel='Criar tag ' onAddItem={this.handleNewtag.bind(this)} onChange={this.handleTagChange.bind(this)} />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancelar' onClick={()=>this.setState({ contactModalOpen: false })} />
            <Button color='olive' labelPosition='right' icon='check' content={this.state.editingContact===-1?'Adicionar':'Salvar'} onClick={ this.saveContact.bind(this) }/>
          </Modal.Actions>
        </Modal>

        <Modal closeOnDimmerClick={false} open={this.state.mainModalOpen} onClose={()=>this.setState({ mainModalOpen: false })}>
          <Modal.Header>{this.state.editingEntity?'Editando':(this.state.entityType==='agency'||this.state.entityType==='network'?'Nova':'Novo')} {this.state.entityType==='agency'?'Agência':(this.state.entityType==='advertiser'?'Anunciante':(this.state.entityType==='network'?'Rede':'Cinema'))}</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading}>
              <Form.Checkbox name='active' label='Ativo' checked={this.state.values.active===1} onChange={(e, { name, checked }) => this.handleInputChange(e, { name, value: checked }) } />
              <Form.Group widths='equal'>
                <Form.Field name='name' label='Nome / Fantasia' control={Input} value={this.state.values.name} icon='user' iconPosition='left' onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='title' label='Razão Social' control={Input} value={this.state.values.title} icon='suitcase' iconPosition='left' onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field name='identity' width='4' label='CPF / CNPJ' control={Input} value={this.state.values.identity} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='register' width='4' label='RG / IE' control={Input} value={this.state.values.register} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='website' width='8' label='Site / Rede Social' control={Input} value={this.state.values.website} icon='world' iconPosition='left' onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
              { this.state.entityType === 'advertiser' &&
                <Form.Dropdown style={{margin:'6px 0 0'}} name='segments' fluid multiple width='16' label='Segmento' value={this.state.values.additional_data.segments||[]} options={this.segments()} search selection placeholder='Selecione um ou mais segmentos (opcional)' noResultsMessage='Nada encontrado' onChange={this.handleInputChange.bind(this)} />
              }
              <Divider section horizontal>Contato</Divider>
              <div style={{marginLeft:'5%',width:'40%',display:'inline-block'}}>
                <List divided>
                  {this.state.values.contacts.map((e, i) => {
                    if (e.type !== 'email') return null;
                    return (
                      <List.Item key={i}>
                        <List.Content floated='right'>
                          <Button primary size='mini' icon='pencil' onClick={() => this.editContact(i)} />
                          <Button negative size='mini' icon='trash' onClick={() => this.removeContact(i)} />
                        </List.Content>
                        <List.Icon name='mail' size='large' verticalAlign='middle' />
                        <List.Content>
                          <List.Header>{e.value}</List.Header>
                          <List.Description>{e.tags.join(', ')}</List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  })}
                </List>
              </div>
              <div style={{marginLeft:'10%',width:'40%',display:'inline-block'}}>
                <List divided>
                  {this.state.values.contacts.map((e, i) => {
                    if (e.type !== 'phone') return null;
                    return (
                      <List.Item key={i}>
                        <List.Content floated='right'>
                          <Button color='blue' size='mini' icon='pencil' onClick={() => this.editContact(i)} />
                          <Button negative size='mini' icon='trash' onClick={() => this.removeContact(i)} />
                        </List.Content>
                        <List.Icon name='phone' size='large' verticalAlign='middle' />
                        <List.Content>
                          <List.Header>{e.value}</List.Header>
                          <List.Description>{e.tags.join(', ')}</List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  })}
                </List>
              </div>
              <div style={{textAlign:'center',marginTop:24}}>
                <Button.Group color='blue'>
                  <Button icon='mail' content='Novo e-mail' onClick={() => this.newContact('email')}/>
                  <Button icon='phone' content='Novo telefone' onClick={() => this.newContact('phone')}/>
                </Button.Group>
              </div>
              { this.state.entityType === 'agency' &&
                [
                  <Divider key={1} section horizontal>Informações adicionais</Divider>,
                  <Form.Group key={2}>
                    <Form.Field name='cut' width='4' label='% de repasse' control={Input} value={formatNumber(this.state.values.additional_data.cut, 2)} onChange={this.handleInputChange.bind(this)} />
                  </Form.Group>
                ]
              }
              { this.state.entityType === 'advertiser' &&
                [
                  <Divider key={1} section horizontal>Agência</Divider>,
                  <Form.Dropdown style={{margin:0}} name='rel_id' fluid key={2} value={this.state.values.rel_id} options={this.agencies()} search selection placeholder='Selecione uma agência' noResultsMessage='Nada encontrado' onChange={this.handleInputChange.bind(this)}  />
                ]
              }
              { this.state.entityType === 'theater' &&
                [
                  <Divider key={1} section horizontal>Informações do cinema</Divider>,
                  <Form.Dropdown style={{margin:0}} name='rel_id' fluid key={2} value={this.state.values.rel_id} options={this.networks()} search selection label='Rede' placeholder='Selecione uma rede' noResultsMessage='Nada encontrado' onChange={this.handleInputChange.bind(this)}  />,
                  <Form.Group widths='equal' key={3}>
                    <Form.Field name='shopping' width='4' label='Shopping' control={Input} value={this.state.values.additional_data.shopping} onChange={this.handleInputChange.bind(this)} />
                    <Form.Field name='public' width='3' label='Público' control={Input} value={this.state.values.additional_data.public} onChange={this.handleInputChange.bind(this)} />
                    <Form.Field name='video_wall_count' width='3' label='Qtd. Videowall' control={Input} value={this.state.values.additional_data.video_wall_count} onChange={this.handleInputChange.bind(this)} />
                    <Form.Field name='bomboniere_count' width='3' label='Qtd. Bomboniere' control={Input} value={this.state.values.additional_data.bomboniere_count} onChange={this.handleInputChange.bind(this)} />
                    <Form.Field name='foyer_count' width='3' label='Qtd. Foyer' control={Input} value={this.state.values.additional_data.foyer_count} onChange={this.handleInputChange.bind(this)} />
                  </Form.Group>
                ]
              }
              <Divider section horizontal>Endereço</Divider>
              <Form.Group widths='equal'>
                <Form.Field name='zip' width='3' label='CEP' control={Input} value={this.state.values.zip} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='address' width='10' label='Logradouro' control={Input} value={this.state.values.address} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='number' width='3' label='Número' control={Input} value={this.state.values.number} onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field name='complement' width='11' label='Complemento' control={Input} value={this.state.values.complement} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='district' width='5' label='Bairro' control={Input} value={this.state.values.district} onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field name='city' width='4' label='Cidade' control={Input} value={this.state.values.city} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='ibge' width='3' label='IBGE' control={Input} value={this.state.values.ibge} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='state' width='4' label='Estado' control={Input} value={this.state.values.state} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='uf' width='2' label='UF' control={Input} value={this.state.values.uf} onChange={this.handleInputChange.bind(this)} />
                <Form.Field name='country' width='3' label='País' control={Input} value={this.state.values.country} onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancelar' onClick={()=>this.setState({ mainModalOpen: false })} />
            <Button color='olive' labelPosition='right' icon='check' content={this.state.editingEntity?'Salvar':'Adicionar'} onClick={this.save.bind(this)} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}