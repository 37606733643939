import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Grid, Message, Loader } from 'semantic-ui-react';
import ContentHeader from './../components/ContentHeader';

class MediaApproval extends Component {
  state = {
    medias: null,
    saving: false
  }

  async componentDidMount() {
    try {
      const ret1 = await window.cs_get('/medias/approval');
      const ret2 = await window.cs_get('/contents/approval');
      this.setState({ medias: (ret1.data||[]).concat(ret2.data||[]) });
    } catch (err) {
      console.error(err);
    }
  }

  async approveMedia(approve) {
    if (!this.state.medias || this.state.medias.length === 0) return;

    try {
      this.setState({ saving: true });
      
      if (this.state.medias[0].customer)
        await window.cs_post('/medias/approval/' + this.state.medias[0].id, { approve });
      else
        await window.cs_post('/contents/approval/' + this.state.medias[0].id, { approve });
      
      let medias = [ ...this.state.medias ];
      medias.splice(0, 1);

      this.setState({ saving: false, medias: medias });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const current = this.state.medias && this.state.medias.length > 0 ? this.state.medias[0] : null;

    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} path={['Aprovação de mídia']}/>
        
        <Grid.Row className='content-margin'>
          <Grid.Column>
            { this.state.medias === null &&
              <Loader active style={{marginTop:'20vh'}}/>
            }

            { this.state.medias && this.state.medias.length === 0 &&
              <Message info header="Arquivos validados" content="Não existe arquivos a serem aprovados" />
            }

            { current && current.customer &&
              <div style={{textAlign:"center"}}>
                <h3 style={{margin:0}}><a href={`/pedidos/${current.order_id}`} target="_blank" rel="noopener noreferrer">#{current.order_id}</a> - { current.customer }</h3>
                <p style={{margin:'0 0 16px'}}>{current.length}s | {current.frame_rate}fps | {current.width}x{current.height}px</p>

                { current.video === 1 &&
                  <video style={{height:'60vh'}} autoPlay controls src={`https://media.unidcinemidia.com.br/medias/${current.order_id}/${current.id}`} />
                }
                { current.video === 0 &&
                  <img style={{width:'60vh'}} alt='' src={`https://media.unidcinemidia.com.br/medias/${current.order_id}/${current.id}`} />
                }

                <div style={{marginTop:14}}>
                  <Button disabled={this.state.saving} color="red" size="big" onClick={()=>this.approveMedia(false)}>Rejeitar</Button>
                  <Button disabled={this.state.saving} color="blue" size="big" onClick={()=>this.approveMedia(true)}>Aprovar</Button>
                </div>
              </div>
            }

            { current && current.network &&
              <div style={{textAlign:"center"}}>
                <h3 style={{margin:'0 0 16px'}}>{ current.network }</h3>

                { current.video === 1 &&
                  <video style={{height:'60vh',maxWidth:'100%'}} autoPlay controls src={`https://media.unidcinemidia.com.br/contents/${current.id}`} />
                }
                { current.video === 0 &&
                  <img style={{height:'60vh',maxWidth:'100%'}} alt='' src={`https://media.unidcinemidia.com.br/contents/${current.id}`} />
                }

                <div style={{marginTop:14}}>
                  <Button disabled={this.state.saving} color="red" size="big" onClick={()=>this.approveMedia(false)}>Rejeitar</Button>
                  <Button disabled={this.state.saving} color="blue" size="big" onClick={()=>this.approveMedia(true)}>Aprovar</Button>
                </div>
              </div>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(MediaApproval);