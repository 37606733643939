import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';

import { Grid, Button, Modal, Label, Select, Form, TextArea, Dimmer, Loader, Segment, Header, Search, Checkbox, Input, Divider } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import LocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import ContentHeader from '../../components/ContentHeader';
import TitledBlock from '../../components/TitledBlock';
import TheatersMap from './components/TheatersMap';

import ProposalItens from './components/ProposalItens';

import { Preshow } from 'unid-core/lib/products/Preshow';
import { Videowall } from 'unid-core/lib/products/Videowall';
import { Bomboniere } from 'unid-core/lib/products/Bomboniere';
import { Foyer } from 'unid-core/lib/products/Foyer';
import { Event } from 'unid-core/lib/products/Event';
import { NamingRights } from 'unid-core/lib/products/NamingRights';
import { PromoAction } from 'unid-core/lib/products/PromoAction';
import { CorporateTicket } from 'unid-core/lib/products/CorporateTicket';
import { VideoEditing } from 'unid-core/lib/products/VideoEditing';
import { VideoMotionGraphics } from 'unid-core/lib/products/VideoMotionGraphics';

import ProductOptions from './components/ProductOptions';

import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { parseProductsFromOrder } from 'unid-core/lib/utils/products';
import IDs from 'unid-core/lib/utils/IDs';

import './NewOrder.css';
class NewOrder extends Component {
  constructor(props, context) {
    super(props, context);
    let initialState = this.cleanState();

    this.advertisersAvailableForUser = [];
    if (this.props.userData.role === IDs.Advertiser) {
      initialState.config.advertiser = this.props.userData.refs[0].id;

      for (let ref of this.props.userData.refs) {
        this.advertisersAvailableForUser.push({
          key: ref.id,
          value: ref.id,
          text: ref.name
        });
      }
    }

    if (props.match.params.id) {
      initialState.editingOrder = undefined;
      this.loadOrder();
    }
    
    this.state = initialState;
  }

  IsUNIDTeam() {
    return this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid;
  }

  IsNetworkTeam() {
    return this.props.userData.role === IDs.Exhibitor || this.props.userData.role === IDs.Marketing || this.props.userData.role === IDs.Manager;
  }

  async loadOrder() {
    try {
      const res = await window.cs_get(`/orders/${this.props.match.params.id}`, { full: true });
      const order = res.data;
      order.additional_data = JSON.parse(order.additional_data);
      
      let hasAgencyCut = false;
      for (let cut of order.cuts)
        if (cut.type === 2) hasAgencyCut = true;
      
      this.setState({
        editingOrder: order,
        products: parseProductsFromOrder(order),
        search_value: order.advertiser_name?order.advertiser_name:'',
        config: {
          active: order.active === 1 ? true : false,
          null_advertiser: order.additional_data && order.additional_data.advertiser_name ? true : false,
          advertiser_name: order.additional_data ? order.additional_data.advertiser_name || '' : '',
          advertiser: order.advertiser_id,
          installments: this.IsUNIDTeam() ? 0 : order.installments,
          installmentsDetails: order.installments_details || [],
          installmentsEditor: order.installments,
          installmentsEditorDetails: order.installments_details || [],
          payment_date: moment(order.payment_date),
          info: order.info,
          has_agency: order.agency_id !== null,
          ignore_agency: order.agency_id !== null && !hasAgencyCut
        }
      });
    } catch(err) {
      console.error(err);
      alert('Não foi possível localizar o pedido com o identificador ' + this.props.match.params.id);
      this.setState({ editingOrder: null });
    }
  }

  cleanState() {
    return {
      loading: false,
      search_value: '',
      searching_advertiser: false,
      showInstallmentEditor: false,
      search_results: [],
      finished: false,
      lastSavedType: null,
      editing: -1,
      editingOrder: null,
      product: null,
      step: 1,
      advertisers: [],
      products: [],
      config: {
        active: true,        
        null_advertiser: this.IsNetworkTeam() ? true : false,
        advertiser_name: '',
        advertiser: null,
        installments: 1,
        installmentsDetails: [],
        installmentsEditor: 1,
        installmentsEditorDetails: [],
        payment_date: moment().add(1, 'day'),
        info: '',
        has_agency: false,
        ignore_agency: false
      }
    };
  }

  reset() {
    this.setState(this.cleanState());
    this.componentDidMount();
  }

  calculeInstallmentsDetails(installments) {
    if (installments === 0) {
      this.calculeInstallmentsEditorDetails(this.state.config.installmentsEditor);
      return;
    }

    const total = this.proposalTotal();
    let installmentPrice = Number(((total||0) / installments).toFixed(2));

    let details = [];
    for (let i = 0; i < installments; i++) {
      details.push({
        price: installmentPrice,
        date: moment(this.state.config.payment_date).add(i, 'months')
      });
    }

    this.setState({ config: { ...this.state.config, installmentsDetails: details }});
  }

  calculeInstallmentsEditorDetails(installments) {
    const total = this.proposalTotal();
    let installmentPrice = Number(((total||0) / installments).toFixed(2));

    let details = [];
    for (let i = 0; i < installments; i++) {
      details.push({
        price: installmentPrice,
        date: moment(this.state.config.payment_date).add(i, 'months')
      });
    }
    
    this.setState({ config: { ...this.state.config, installmentsEditorDetails: details }});
  }

  handleInstallmentEditorInput(e, { name, value }) {
    if (name === 'installments') {
      value = Number(value)||0;
      if (value > 48) value = 48;
      this.setState({ config: { ...this.state.config, installmentsEditor: value }}, () => this.calculeInstallmentsEditorDetails(value||1));
      return;
    }

    const args = name.split('_');
    const idx = Number(args[1]);

    let details = this.state.config.installmentsEditorDetails;

    if (args[0] === 'date') {
      details[idx].date = moment(value);
      this.setState({ config: { ...this.state.config, installmentsEditorDetails: details }});
      return;
    }

    if (args[0] === 'price') {
      details[idx].custom = true;
      details[idx].price = parseInt(value.replace(/\.|,/g, ''), 10) / 100;

      // Soma todos os valores bloqueados
      let sum = 0;
      let installments = this.state.config.installmentsEditor;

      for (let detail of details) {
        if (detail.custom) {
          sum += detail.price;
          installments--;
        }
      }
      
      // Atualiza todos os valores que não estão bloqueados
      let newInstallmentPrice = Number((((this.proposalTotal()||0)-sum) / installments).toFixed(2));
      if (newInstallmentPrice < 0) newInstallmentPrice = 0;

      for (let detail of details) {
        if (!detail.custom) detail.price = newInstallmentPrice;
      }

      this.setState({ config: { ...this.state.config, installmentsEditorDetails: details }});
      return;
    }
  }

  handleInput(e, { name, value, checked }) {
    if (name === 'null_advertiser' || name === 'ignore_agency') value = checked;
    this.setState({ config: { ...this.state.config, [name]: value }}, () => {
      if (name === 'installments') this.calculeInstallmentsDetails(value);
    });
  }

  handlePaymentDateChange(date) {
    date = moment(date);
    this.setState({ config: { ...this.state.config, payment_date: date }}, () => this.calculeInstallmentsDetails(this.state.config.installments));
  }

  hasAnyTheaterSelected() {
    if (this.state.product && this.state.product.theaterLess) return true;
    if (!this.state.product || !this.state.product.theaters) return false;
    return this.state.product.theaters.length !== 0;
  }
  
  productOnClick(product) {
    window.ga_event(this.props.userData, {
      category: 'Compra Online',
      action: 'Clicou em produto',
      label: product.name
    });

    this.setState({ product, step: product.theaterLess ? 2 : 1 });
  }

  addProduct() {
    window.ga_event(this.props.userData, {
      category: 'Compra Online',
      action: 'Adicionou produto',
      label: this.state.product.name
    });

    const product = this.state.product;
    if (product.id === IDs.CorporateTicket && product.quotas < 50) product.quotas = 50;

    let newProducts = [ ...this.state.products ];
    if (this.state.editing !== -1) newProducts[this.state.editing] = product;
    else newProducts.push(product);

    this.setState({ editing: -1, product: null, products: newProducts }, () => this.calculeInstallmentsDetails(this.state.config.installments));
  }

  removeProduct(i) {
    let newProducts = [ ...this.state.products ];
    newProducts.splice(i, 1);
    this.setState({ products: newProducts });
  }

  editProduct(i) {
    this.setState({ editing: i, product: this.state.products[i], step: this.state.products[i].theaterLess ? 2 : 1 });
  }

  goToOptions() {
    const product = this.state.product;
    const markers = this.refs.theatersMap.state.theatersMarkers;

    let selectedTheaters = [];
    for (let marker of markers) {
      for (let theater of marker.theaters) {
        if (theater.selected) selectedTheaters.push(theater);
      }
    }
    
    product.theaters = selectedTheaters;
    this.setState({ product: product, step: 2 });
  }

  orderToObject(type) {
    let advertiser = this.state.config.advertiser;

    // Parcelas detalhadas
    const detailsList = this.state.config.installments === 0 ? this.state.config.installmentsEditorDetails : this.state.config.installmentsDetails;
    
    let obj = {
      active: this.state.config.active,
      type,
      advertiser: advertiser,
      installments: detailsList.length,
      installments_details: [],
      payment_date: this.state.config.payment_date.toDate(),
      items: [],
      info: this.state.config.info,
      additional_data: {},
      ignore_agency: this.state.config.ignore_agency
    };

    if (this.state.config.null_advertiser)
      obj.additional_data.advertiser_name = this.state.config.advertiser_name;

    for (let product of this.state.products) {
      obj.items.push(product.toObject());
    }

    // Cria o array de parcelas
    for (let detail of detailsList) {
      obj.installments_details.push({ custom: detail.custom || false, date: moment(detail.date).toDate(), price: detail.price });
    }

    obj.additional_data = JSON.stringify(obj.additional_data);
    return obj;
  }

  async saveProposal() {
    this.setState({ loading: true });

    try {
      if (this.state.editingOrder) {
        await window.cs_post(`/orders/${this.state.editingOrder.id}`, this.orderToObject(IDs.Proposal));
        this.setState({ loading: false, finished: true, lastSavedType: IDs.Proposal });
      } else {
        await window.cs_post('/orders', this.orderToObject(IDs.Proposal));
        this.setState({ loading: false, finished: true, lastSavedType: IDs.Proposal });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async savePI() {
    this.setState({ loading: true });
    
    try {
      if (this.state.editingOrder) {
        await window.cs_post(`/orders/${this.state.editingOrder.id}`, this.orderToObject(IDs.PI));
        this.setState({ loading: false, finished: true, lastSavedType: IDs.PI });
      } else {
        await window.cs_post('/orders', this.orderToObject(IDs.PI));
        this.setState({ loading: false, finished: true, lastSavedType: IDs.PI });
      }
    } catch (err) {
      console.error(err);
    }
  }

  proposalTotal() {
    let total = 0;
    for (let product of this.state.products) total += product.total();
    return total;
  }

  installments() {
    const total = this.proposalTotal();

    let biggerPeriod = 0;
    for (let product of this.state.products) {
      if (product.weeks() > biggerPeriod)
        biggerPeriod = product.weeks();
    }
    let maxInstallments = 12;

    if (biggerPeriod <= 36) maxInstallments = 6;
    if (biggerPeriod <= 12) maxInstallments = 3;
    if (biggerPeriod <= 4) maxInstallments = 1;
    
    let ret = [];
    if (this.IsUNIDTeam())
      ret.push({ key: 'custom', value: 0, text: 'Personalizado' });
    for (let i = 0; i < maxInstallments; i++) {
      const value = total / (i+1);
      ret.push({ key: i+1, value: i+1, text: `${i+1}x de ${formatCurrency(value)}` });
    }
    
    if (this.state.config.installments > maxInstallments)
      setTimeout(() => {
        this.setState({ config: { ...this.state.config, installments: 1 }}, () => this.calculeInstallmentsDetails(1));
      }, 500);

    return ret;
  }

  advertiserSearch(e, { value }) {
    this.setState({ searching_advertiser: true, search_value: value, results: [] });

    setTimeout(() => {
      let match = new RegExp(value, 'i');
      
      let results = this.state.advertisers.filter((e) => {
        return match.test(e.name);
      });
  
      let results_processed = [];
      for (let result of results) {
        results_processed.push({ id: result.id, title: result.name, rel_id: result.rel_id });
      }
  
      this.setState({ searching_advertiser: false, search_results: results_processed });
    }, 200);
  }

  advertiserSelect(e, { result }) {
    this.setState({ search_value: result.title, config: { ...this.state.config, advertiser: result.id, has_agency: result.rel_id !== null }});
  }

  productOptionsTitle() {
    if (!this.state.product) return '';
    if (this.state.product.id === IDs.NamingRights) return 'Definições da meia entrada ilimitada';
    if (this.state.product.id === IDs.PromoAction) return 'Definições da ação';
    if (this.state.product.id === IDs.VideoEditing || this.state.product.id === IDs.VideoMotionGraphics) return 'Definições da produção de vídeo';
    return 'Definições de veiculação';
  }

  async componentDidMount() {
    if (this.IsUNIDTeam()) {
      try {
        const res = await window.cs_get('/entities', { type: 3, simple: 2 });
        this.setState({ advertisers: res.data });
      } catch (err) {
        console.error(err);
      }
    }
  }

  render() {
    if (this.state.finished) {
      return (
        <Grid padded className='Order'>
          <ContentHeader userData={this.props.userData} path={['Compra Online']}/>

          { (this.state.lastSavedType === IDs.Proposal && this.IsNetworkTeam()) &&
            <TitledBlock title='proposta criada'>
              <Segment>
                <Header>Proposta enviada!</Header>
                <p>A proposta foi enviada com sucesso.</p>
                <Button style={{width:230,marginLeft:0}} primary onClick={this.reset.bind(this)}>Criar uma nova proposta.</Button>
              </Segment>
            </TitledBlock>
          }

          { (this.state.lastSavedType === IDs.Proposal && !this.IsNetworkTeam()) &&
            <TitledBlock title='proposta criada'>
              <Segment>
                <Header>Proposta {this.state.editingOrder?'salva':'criada'}!</Header>
                <p>A proposta foi {this.state.editingOrder?'salva':'criada'} com sucesso.</p>
                <Link to='/propostas'><Button style={{width:230}} primary>Ir para lista de propostas</Button></Link> ou <Button style={{width:230}} primary onClick={this.reset.bind(this)}>criar uma nova proposta.</Button>
              </Segment>
            </TitledBlock>
          }

          { this.state.lastSavedType === IDs.PI && 
            <TitledBlock title='pedido criada'>
              <Segment>
                <Header>Pedido {this.state.editingOrder?'salvo':'criado'}!</Header>
                <p>O pedido foi {this.state.editingOrder?'salvo':'criado'} com sucesso.</p>
                <Link to='/pedidos'><Button style={{width:230}} primary>Ir para lista de pedidos</Button></Link> ou <Button style={{width:230}} primary onClick={this.reset.bind(this)}>criar um novo anúncio.</Button>
              </Segment>
            </TitledBlock>
          }
        </Grid>
      );
    }

    return (
      <Grid padded className='Order'>
        <ContentHeader userData={this.props.userData} path={['Compra Online']}/>
        
        <Dimmer active={this.state.loading||this.state.editingOrder===undefined} inverted>
          <Loader size='large'>{this.state.editingOrder===undefined?'Carregando dados...':'Enviando...'}</Loader>
        </Dimmer>
        
        <TitledBlock title='produtos disponíveis'>
          <div style={{textAlign:'center'}}>
            <Button onClick={() => this.productOnClick(new Preshow())}>
              <span className="icone-midia-em-tela"></span><br />Mídia em Tela
            </Button>
            <Button onClick={() => this.productOnClick(new Videowall())}>
              <span className="icone-videowall"></span><br />Videowall
            </Button>
            <Button onClick={() => this.productOnClick(new Bomboniere())}>
              <span className="icone-bomboniere"></span><br />Bomboniere
            </Button>
            <Button onClick={() => this.productOnClick(new Foyer())}>
              <span className="icone-foyer"></span><br />Foyer
            </Button>
            <Button onClick={() => this.productOnClick(new Event())}>
              <span className="icone-eventos"></span><br />Evento
            </Button>
            <Button onClick={() => this.productOnClick(new NamingRights())}>
              <span className="icone-naming-rights"></span><br />Naming Rights
            </Button>
            <Button onClick={() => this.productOnClick(new PromoAction())}>
              <span className="icone-megafone-contorno"></span><br />Ação Promocional
            </Button>
            <Button style={{lineHeight:'14px'}} onClick={() => this.productOnClick(new CorporateTicket())}>
              <span className="icone-ingresso"></span><br />Ingresso Corporativo
            </Button>
            <Button onClick={() => this.productOnClick(new VideoEditing())}>
              <span className="icone-fita-video"></span><br />Edição de Vídeo
            </Button>
            <Button style={{lineHeight:'14px'}} onClick={() => this.productOnClick(new VideoMotionGraphics())}>
              <span className="icone-fita-video"></span><br />Vídeo Motion Graphics
            </Button>
          </div>
        </TitledBlock>

        { this.state.products.length !== 0 &&
          [
            <TitledBlock key={1} title='resumo da proposta'>
              <ProposalItens products={this.state.products} removeProduct={this.removeProduct.bind(this)} editProduct={this.editProduct.bind(this)} />
            </TitledBlock>,
            <TitledBlock key={2} title={this.IsUNIDTeam()?'informações do cliente e pagamento':null}>
              { (this.IsUNIDTeam() || this.IsNetworkTeam()) && !(this.state.editingOrder && this.state.editingOrder.type === IDs.PI) &&
                <Grid.Row className='content-margin'>
                  <Grid.Column style={{textAlign:'center',paddingBottom:4}}>
                    { !this.IsNetworkTeam() && <Checkbox name='null_advertiser' checked={this.state.config.null_advertiser} onChange={this.handleInput.bind(this)} label='Anunciante sem cadastro' /> }
                    { !this.state.config.null_advertiser && this.state.config.has_agency && <Checkbox style={{marginLeft:12}} name='ignore_agency' checked={this.state.config.ignore_agency} onChange={this.handleInput.bind(this)} label='Ignorar agência' /> }
                  </Grid.Column>
                </Grid.Row>
              }
              
              { (this.IsUNIDTeam() || this.IsNetworkTeam()) &&
                <Grid.Row className='content-margin'>
                  <Grid.Column style={{textAlign:'center'}}>
                    { !this.state.config.null_advertiser &&
                      <Search size='small' fluid loading={this.state.searching_advertiser} minCharacters={3} value={this.state.search_value} showNoResults={!this.state.searching_advertiser} noResultsMessage='Nenhum anunciante encontrado' input={{placeholder:'Pesquisar anunciante...'}} results={this.state.search_results} onSearchChange={this.advertiserSearch.bind(this)} onResultSelect={this.advertiserSelect.bind(this)} />
                    }
                    { this.state.config.null_advertiser &&
                      <Input name='advertiser_name' value={this.state.config.advertiser_name} style={{width:'35%'}} placeholder='Nome do anunciante' size='small' onChange={this.handleInput.bind(this)} />
                    }
                  </Grid.Column>
                </Grid.Row>
              }

              { !this.IsUNIDTeam() && this.advertisersAvailableForUser.length > 1 &&
                <Grid.Row className='content-margin'>
                  <Grid.Column style={{textAlign:'center'}}>
                    <p style={{marginBottom:4,fontSize:12}}>Selecione o cadastro que deseja associar a proposta</p>
                    <Select style={{display:'inline-block',width:'35%'}} value={this.state.config.advertiser} fluid size='small' options={this.advertisersAvailableForUser} onChange={(e, { value }) => this.advertiserSelect(e, { result: { id: value }}) } />
                  </Grid.Column>
                </Grid.Row>
              }

              <Grid.Row className='content-margin'>
                <Grid.Column style={{textAlign:'center'}}>
                  <Label style={{margin:'8px 4px',padding:'8px 8px 8px 16px',fontSize:14,color:'#026e95'}}>
                    PARCELAMENTO:
                    <Select name='installments' value={this.state.config.installments} options={this.installments()} style={{marginLeft:12,fontWeight:400}} onChange={this.handleInput.bind(this)}/>
                    { this.state.config.installments === 0 && <Button color='olive' style={{margin:'0 0 0 8px'}} icon='setting' onClick={()=>this.setState({ showInstallmentEditor: true })}/> }
                  </Label>
                  { this.state.config.installments !== 0 &&
                    <Label style={{margin:'8px 4px',padding:'8px 8px 8px 16px',fontSize:14,color:'#026e95',lineHeight:'38px'}}>
                      VENCIMENTO:
                      <Form style={{display:'inline-block'}}>
                        <Form.Group style={{margin:0}}>
                          <div className='field'>
                            <div className='ui input' style={{marginLeft:12,width:130}}>
                              <DayPickerInput onDayChange={this.handlePaymentDateChange.bind(this)} placeholder="DD/MM/YYYY" value={moment(this.state.config.payment_date).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: [{ before: moment().add(1, 'day').toDate() }] }} />
                            </div>
                          </div>
                        </Form.Group>
                      </Form>
                    </Label>
                  }
                  <Label style={{margin:'8px 4px',padding:'20px 16px',fontSize:14,color:'#026e95'}}>
                    VALOR TOTAL DO PEDIDO:
                    <span style={{fontWeight:400,marginLeft:8,color:'#6d7073'}}>{formatCurrency(this.proposalTotal())}</span>
                  </Label>
                </Grid.Column>
              </Grid.Row>,
              <Grid.Row className='content-margin'>
                <Grid.Column>
                  <p>Informações adicionais</p>
                  <Form>
                    <TextArea name='info' value={this.state.config.info} onChange={this.handleInput.bind(this)} />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </TitledBlock>,
            <Grid.Row key={3} className='content-margin'>
              <Grid.Column style={{textAlign:'center'}}>
                { (!this.state.editingOrder || (this.state.editingOrder && this.state.editingOrder.type === IDs.Proposal)) &&
                  <Button primary disabled={(this.state.config.advertiser===null&&!this.state.config.advertiser_name&&this.IsUNIDTeam())||(this.state.config.null_advertiser&&!this.state.config.advertiser_name)} style={{padding:'16px 24px'}} onClick={() => this.saveProposal(this)}>{ this.IsNetworkTeam() ? 'ENVIAR' : 'SALVAR' } PROPOSTA</Button>
                }
                { this.IsUNIDTeam() &&
                  <Button primary disabled={this.state.config.advertiser===null||this.state.config.null_advertiser} style={{padding:'16px 24px'}} onClick={() => this.savePI(this)}>{this.state.editingOrder && this.state.editingOrder.type === IDs.Proposal ? 'CONVERTER P/ PI' : 'SALVAR PI'}</Button>
                }
              </Grid.Column>
            </Grid.Row>
          ]
        }

        { this.state.product &&
          <Modal open size={this.state.step === 2 ? 'small' : 'large'}>
            <Modal.Header>{ this.state.step === 1 ? 'Seleção dos complexos' : this.productOptionsTitle() }</Modal.Header>
            <Modal.Content style={{padding:0}}>
              { this.state.step === 1 &&
                <TheatersMap ref='theatersMap' product={this.state.product} theaters={this.state.product?this.state.product.theaters:null} userData={this.props.userData}/>
              }
              { this.state.product && this.state.step === 2 &&
                <ProductOptions product={this.state.product} userData={this.props.userData}/>
              }
            </Modal.Content>
            <Modal.Actions>
              <Button negative content='Cancelar' onClick={()=>this.setState({product:null,editing:-1})} />
              
              { this.state.step === 1 &&
                <Button color='blue' labelPosition='right' icon='chevron right' content='Avançar' onClick={this.goToOptions.bind(this)} />
              }
              { this.state.step === 2 &&
                [
                  <Button style={{display:this.state.product&&this.state.product.theaterLess?'none':null}} key={1} color='blue' labelPosition='left' icon='chevron left' content='Voltar' onClick={()=>this.setState({step:1})} />,
                  <Button key={2} disabled={!this.hasAnyTheaterSelected()} color='olive' labelPosition='right' icon='check' content={this.state.editing !== -1 ? 'Salvar' : 'Adicionar'} style={{marginLeft:0}} onClick={this.addProduct.bind(this)} />
                ]
              }
            </Modal.Actions>
          </Modal>
        }

        <Modal open={this.state.showInstallmentEditor} size='mini'>
          <Modal.Header>Configuração das parcelas</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group>
                <div className='eight wide field'>
                  <label>Vencimento 1ª parcela</label>
                  <DayPickerInput onDayChange={this.handlePaymentDateChange.bind(this)} value={moment(this.state.config.payment_date).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR' }} />
                </div>
                <Form.Input autoComplete='off' width='8' name='installments' value={this.state.config.installmentsEditor} label='Qtd. parcelas' onChange={this.handleInstallmentEditorInput.bind(this)}/>
              </Form.Group>
              <Divider />
              { this.state.config.installmentsEditorDetails.map((e, i) => {
                return (
                  <Form.Group key={i}>
                    <div className='eight wide field'>
                      <DayPickerInput onDayChange={(date)=>this.handleInstallmentEditorInput(null, { name: `date_${i}`, value: date })} value={moment(e.date).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR' }} />
                    </div>
                    <Form.Input autoComplete='off' width='8' name={`price_${i}`} value={formatNumber(e.price, 2)} onChange={this.handleInstallmentEditorInput.bind(this)} />
                  </Form.Group>
                );
              })}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content='Fechar' onClick={()=>this.setState({ showInstallmentEditor: false })} />
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

export default withRouter(NewOrder);