import React, { Component } from 'react';

import { Accordion, Icon, Table, Button, Header } from 'semantic-ui-react';

import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { sortTheatersByState } from 'unid-core/lib/utils/products';

import IDs from 'unid-core/lib/utils/IDs';

import './ProposalItens.css';
export default class ProposalItens extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeIndex: -1
    };
  }

  // MULTIPLES LOCATIONS
  productHeader(product) {
    const startDateStr = product.startDate.format('DD/MM/YYYY');
    const endDateStr = product.endDate.format('DD/MM/YYYY');

    let quotasStr = `${product.quotas} ${product.quotas < 2 ? 'cota' : 'cotas'}`;
    const weeksStr =  `${product.weeks()} ${product.weeks() < 2 ? 'semana' : 'semanas'}`;

    if (product.id === IDs.Preshow || product.id === IDs.Videowall || product.id === IDs.Bomboniere || product.id === IDs.Foyer || product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics)
      quotasStr += ` de ${product.quotaType}s`;
    
    if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics)
      return `${product.name.toUpperCase()} - ${quotasStr}`;

    if (product.id === IDs.NamingRights)
      return `${product.name.toUpperCase()} - ${product.quotas} pessoas - ${startDateStr} a ${endDateStr} (${weeksStr})`;

    if (product.id === IDs.Event) {
      let period = '';
      if (startDateStr === endDateStr) period = ' em ' + startDateStr;
      else period = `de ${startDateStr} a ${endDateStr} (${product.days()} dias)`;
      
      return `${product.name.toUpperCase()} - ${product.quotas} pessoas ${period}`;
    }

    if (product.id === IDs.PromoAction)
      return `${product.name.toUpperCase()} - ${startDateStr} a ${endDateStr} (${weeksStr})`;
    
    if (product.id === IDs.CorporateTicket)
      return `${product.name.toUpperCase()} - ${product.quotas} ${product.quotas < 2 ? 'ingresso' : 'ingressos'}`;

    return `${product.name.toUpperCase()} - ${quotasStr} - ${startDateStr} a ${endDateStr} (${weeksStr})`;
  }

  productContent(product, idx) {
    if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics || product.id === IDs.CorporateTicket) {
      return [
        <p key={0} style={{whiteSpace:'pre-line'}}>{ product.additionalData ? product.additionalData.desc||'Nenhuma descrição informada' : 'Nenhuma descrição informada' }</p>,
        <div style={{height:28}} key={1}>
          <Button negative content='Remover' icon='trash' labelPosition='right' floated='right' onClick={()=>this.props.removeProduct(idx)}/>
          <Button primary content='Editar' icon='edit' labelPosition='right' floated='right' onClick={()=>this.props.editProduct(idx)}/>
        </div>
      ];
    }

    return [
      <p key={0} style={{display:product.additionalData&&product.additionalData.desc?'block':'none',whiteSpace:'pre-line'}}>{product.additionalData.desc}</p>,
      <Table celled structured key={1}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan='2'>PRAÇA</Table.HeaderCell>
            <Table.HeaderCell colSpan='3'>CINEMA</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>REDE</Table.HeaderCell>
            <Table.HeaderCell>COMPLEXO</Table.HeaderCell>
            <Table.HeaderCell>INFORMAÇÕES</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { sortTheatersByState(product).map((state, i) => {
            return state.cities.map((city, j) => {
              if (!city.selectedCount) return null;

              let row = [];
              let cityInserted = false;
              let lastNetwork = '';
              
              for (let theater of city.theaters) {
                if (!theater.selected) continue;

                const networkName = theater.network ? theater.network.name : theater.name;

                row.push(
                  <Table.Row key={`${i}${j}_theater_${theater.id}`}>
                    { !cityInserted && <Table.Cell rowSpan={city.selectedCount} style={{color:'#026e95',fontWeight:700}}>{city.theaters[0].city} ({city.theaters[0].uf})</Table.Cell> }
                    { lastNetwork !== networkName && <Table.Cell rowSpan={theater.networkSelectedCount}>{networkName}</Table.Cell> }
                    <Table.Cell>{theater.additional_data.shopping}</Table.Cell>
                    <Table.Cell>
                      <span className='icone-midia-em-tela' style={{position:'relative',display:'inline-block',top:2,fontSize:16,margin:0}}></span> {theater.stats.rooms}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className='icone-poltrona' style={{position:'relative',display:'inline-block',top:2,fontSize:16,margin:0}}></span> {formatNumber(theater.stats.seats)}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className='icone-grupo' style={{position:'relative',display:'inline-block',top:2,fontSize:16,margin:0}}></span> {formatNumber(theater.stats.public)}
                    </Table.Cell>
                  </Table.Row>
                );

                cityInserted = true;
                lastNetwork = networkName;
              }

              return row;
            });
          })}
        </Table.Body>
      </Table>,
      <div style={{height:28}} key={2}>
        <Button negative content='Remover' icon='trash' labelPosition='right' floated='right' onClick={()=>this.props.removeProduct(idx)}/>
        <Button primary content='Editar' icon='edit' labelPosition='right' floated='right' onClick={()=>this.props.editProduct(idx)}/>
      </div>
    ];
  }

  render() {
    return (
      <Accordion fluid styled style={{borderRadius:1}} className='ProposalItens'>
        { this.props.products.map((product, i) => {
          return (
            [
              <Accordion.Title key={`title_${i}`} active={this.state.activeIndex === i} onClick={()=>this.setState({ activeIndex: this.state.activeIndex === i ? -1 : i })}>
                <Icon name='dropdown' />
                { this.productHeader(product) }
                <Header sub color='olive' floated='right' style={{marginTop:4}}>{formatCurrency(product.total())}</Header>
              </Accordion.Title>,
              <Accordion.Content key={`content_${i}`} active={this.state.activeIndex === i}>
                { this.productContent(product, i) }
              </Accordion.Content>
            ]
          )
        })}
      </Accordion>
    );
  }
}