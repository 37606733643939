import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { Grid, Loader, Button, List, Header, Icon, Message, Menu, Input, Popup } from 'semantic-ui-react';
import ContentHeader from '../components/ContentHeader';

import { getTheaterById } from 'unid-core/lib/Theaters';
import { removeAccents } from 'unid-core/lib/utils/string';

export default class Players extends Component {
  state = {
    refreshMoment: moment(),
    page: 0,
    searchText: '',
    search: undefined,
    list: undefined,
    restarting: undefined
  };

  async restartPlayer(serial) {
    try {
      this.setState({ restarting: serial });
      
      if (this.rtimer) clearTimeout(this.rtimer);
      this.rtimer = setTimeout(() => {
        this.rtimer = undefined;
        this.setState({ restarting: false });
      }, 2000);

      await window.cs_post(`/players/${serial}/flags`, { flags: 1 });
    } finally {}
  }

  doSearchKey(key, value, e) {
    if (!value || !e[key]) return false;
    return removeAccents(e[key]).toLowerCase().indexOf(removeAccents(value).toLowerCase()) !== -1;
  }

  doSearch(value) {
    this.setState({ searchText: value, page: 0 });
    
    if (!value) {
      this.setState({ search: null });
      return;
    }

    let fine_results = [];
    let sparse_results = [];
    
    for (const player of this.state.list) {
      if (value === 'online' && this.state.refreshMoment.diff(moment(player.last_ping_time), 'minutes', true) < 5) {
        fine_results.push(player);
        continue;
      }

      if (value === 'offline' && this.state.refreshMoment.diff(moment(player.last_ping_time), 'minutes', true) >= 5) {
        fine_results.push(player);
        continue;
      }

      if (this.doSearchKey('name', value, player))
        fine_results.push(player);
      if (this.doSearchKey('theaterName', value, player))
        sparse_results.push(player);
      if (this.doSearchKey('networkName', value, player))
        sparse_results.push(player);
    }
   
    let allResults = fine_results.concat(sparse_results);
    
    function isPresentInArray(e, beforePos) {
      for (let i = 0; i < beforePos; i++) {
        if (allResults[i].name === e.name) return true;
      }
      return false;
    }

    for (let i = allResults.length -1; i >= 0; i--) {
      if (isPresentInArray(allResults[i], i)) allResults.splice(i, 1);
    }

    this.setState({ search: allResults });
  }

  playersPerPage = 12;

  playersList() {
    let players = this.state.search || this.state.list;
    let list = [];

    for (let i = this.state.page * this.playersPerPage; i < players.length; i++) {
      const e = players[i];
      if (list.length >= this.playersPerPage) break;

      let online = false;
      let serviceOnline = true;

      if (e.last_ping_time) {
        online = this.state.refreshMoment.diff(moment(e.last_ping_time), 'minutes', true) < 5;
      }

      if (e.last_service_ping_time) {
        serviceOnline = this.state.refreshMoment.diff(moment(e.last_service_ping_time), 'minutes', true) < 5;
      }

      list.push(
        <List.Item key={i}>
          <List.Content floated='right'>

            <Popup content={`${online?'Último ping em':'Offline desde'} ${moment(e.last_ping_time).format('DD/MM/YY - HH:mm')}`} trigger={<Header as='h1' size='small' color={online?'olive':'red'} style={{display:'inline-block',marginRight:18}}>{online?'ONLINE':'OFFLINE'}</Header>} />
            <Button disabled={!serviceOnline} color='olive' icon size='small' onClick={()=>this.restartPlayer(e.serial)}>
              <Icon fitted name='sync alternate' loading={this.state.restarting===e.serial} />
            </Button>
            <Link to={`/telas/${e.name}`}><Button primary size='small' icon='setting'/></Link>
          </List.Content>

          <List.Content>
            <List.Header>{e.name}{` [${e.version||'?'}]`}</List.Header>
            <List.Description style={{fontSize:11,marginTop:2}}>{getTheaterById(e.theater_id).name}</List.Description>
          </List.Content>
        </List.Item>
      );
    }

    return list;
  }

  pagination() {
    let players = this.state.search || this.state.list;
    const pageCount = Math.ceil(players.length / this.playersPerPage);

    if (pageCount <= 1) return null;

    let startIdx = 0;
    let endIdx = pageCount;

    if (pageCount > 7) {
      startIdx = this.state.page - 3;
      endIdx = this.state.page + 4;

      const left_factor = this.state.page - 3;
      const right_factor = (this.state.page + 4) - pageCount;

      if (left_factor < 0) {
        startIdx = 0;
        endIdx += Math.abs(left_factor);
      }

      if (right_factor > 0) {
        startIdx -= right_factor;
        endIdx -= right_factor;
      }
    }

    let pages = [];
    if (startIdx !== 0)
      pages.push(<Menu.Item key={0} onClick={()=>this.setState({ page: 0 })}>&lt;&lt; Início</Menu.Item>);

    for (let i = startIdx; i < endIdx; i++) {
      pages.push(<Menu.Item key={i} active={i === this.state.page} onClick={()=>this.setState({ page: i })}>{i+1}</Menu.Item>);
    }

    if (endIdx < pageCount)
      pages.push(<Menu.Item key={pageCount-1} onClick={()=>this.setState({ page: pageCount-1 })}>Fim &gt;&gt;</Menu.Item>);

    return (
      <Menu pagination size='mini' >
        { pages }
      </Menu>
    );
  }

  async componentDidMount() {
    try {
      const res = await window.cs_get('/players');
      this.setState({ refreshMoment: moment(), list: res.data.map((e) => {
        const theater = getTheaterById(e.theater_id);

        return {
          ...e,
          networkName: theater.network.name,
          theaterName: theater.name
        }
      }) });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} path={['Players']}/>

        { !this.state.list && <Loader active={true} size='large' style={{marginTop:200}}>Carregando...</Loader> }

        { this.state.list && this.state.list.length === 0 &&
          <Grid.Row className='content-margin'>
            <Grid.Column>
              <Message warning>
                <Message.Header>Nada encontrado!</Message.Header>
                <p>Não existe nenhum player associado à sua conta.</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list && this.state.list.length > 0 &&
          <Grid.Row className='content-margin' style={{marginBottom:0}}>
            <Grid.Column>
              <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch(value)} />
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list &&
          <Grid.Row className='content-margin'>
            <Grid.Column>
              <List divided relaxed='very'>
                { this.playersList() }
              </List>
            </Grid.Column>
          </Grid.Row>
        }

        { this.state.list &&
          <Grid.Row style={{paddingTop:0,marginTop:0}}>
            <Grid.Column style={{textAlign:'center'}}>
              { this.pagination() }
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    );
  }
}