import React, { Component } from 'react';
import { Accordion, Dimmer, Loader, Icon, List, Button, Select, Modal, Message, Visibility } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import IDs from 'unid-core/lib/utils/IDs';
import { parseProductsFromOrder } from 'unid-core/lib/utils/products';
import { plurarify } from 'unid-core/lib/utils/string';

import './OrderQuotaEditor.css';
export default class OrderQuotaEditor extends Component {
  order = null;

  state = {
    activeIndex: 0,
    medias: undefined,
    attaching: false,
    products: [],
    add_idx: undefined,
    add_theater: undefined,
    selections: {},
    programming: undefined,
    programming_select: undefined,
    programmingSelectPeriod: false,
    quota_usage: []
  }

  addMedia(product, theater, remainingSeconds) {
    let opts = this.mediasOptions(product, remainingSeconds);

    let opt = undefined;
    let optCount = 0;

    for (let o of opts) {
      if (!o.disabled && o.value) {
        opt = o;
        optCount++;
      }
    }

    if (optCount === 1) {
      this.onMediaChange(product, theater, opt.value);
    } else if (optCount > 1) {
      this.setState({ add_idx: product.parsedObject.idx, add_theater: theater.id });
    }
  }

  async removeAttachment(attachment) {
    try {
      await window.ms_delete(`/attachments/${attachment.id}`);
      this.listAttachments();
    } catch (err) {
      console.error(err);
    }
  }

  async onMediaChange(product, theater, media) {
    this.setState({ add_idx: undefined, attaching: true });
    if (!media) return;

    try {
      await window.ms_post(`/attachments/${this.order.id}`, { item_idx: product.parsedObject.idx, theater: theater.id, media });
      this.listAttachments();
    } catch (err) {
      console.error(err);
    }
  }

  async listAttachments() {
    try {
      const res = await window.ms_get(`/attachments/${this.order.id}`);

      let s = {};
      for (let i of res.data.attachments) {
        if (!s[i.order_item_idx]) s[i.order_item_idx] = [i];
        else s[i.order_item_idx].push(i);
      }

      this.setState({ programmingSelectPeriod: res.data.programmingSelectPeriod, selections: s, attaching: false });
    } catch(err) {
      console.error(err);
    }
  }

  async showProgrammingSelector(attachment) {
    this.setState({ programming_select: attachment });

    try {
      const res = await axios.all([
        window.cs_get('/programming?theater-id='+attachment.theater_id),
        window.cs_get(`/programming/${attachment.theater_id}/room-quota-usage`)
      ]);

      this.setState({ programming: res[0].data, quota_usage: res[1].data });
    } catch (err) {
      this.setState({ programming_select: null });
      console.error(err);
    }
  }

  async selectRoom(room) {
    const { id } = this.state.programming_select;
    this.setState({ programming_select: null });

    try {
      await window.ms_post(`/attachment/${id}/room`, { room });
    } catch (err) {
      toast.error('Não foi possível selecionar a sala. Tente novamente mais tarde e se o problema persistir, entre em contato conosco.', { autoClose: 10000, closeButton: true });
      console.error(err);
    } finally {
      this.listAttachments();
    }
  }

  async startupCode() {
    if (this.order) return;

    if (!isNaN(this.props.order)) {
      this.order = (await window.cs_get(`/orders/${this.props.order}`, { full: true })).data;
      const res = await window.ms_get(`/medias/order/${this.props.order}`);
      this.setState({ activeIndex: undefined, medias: res.data });
    } else this.order = this.props.order;

    this.setState({ products: parseProductsFromOrder(this.order) }, () => {
      this.listAttachments();
    });
  }

  // MULTIPLES LOCATIONS (ProgrammingAdmin.js)
  renderProgramming() {
    if (!this.state.programming_select) return null;
    if (!this.state.programming) return <p style={{textAlign:'center',margin:'40px 0 20px'}}><Icon loading name='spinner' /> Carregando programação...</p>;

    let programming = null;
    for (let p of this.state.programming) {
      if (this.state.programming_select.theater_id === p.theater_id) {
        programming = p;
        break;
      }
    }

    if (!programming)
      return (
        <Message warning>
          <Message.Header>Programação não disponível!</Message.Header>
          <p>A programação ainda não foi disponibilizada por nossos parceiros. Por favor aguarde alguns minutos e tente novamente.</p>
        </Message>
      );

    const rooms = programming.rooms;

    return (
      <div className='TheaterProgramming' style={{marginLeft:-10}}>
        { !this.state.programmingSelectPeriod &&
          <Message info>
            <Message.Header>Seleção de sala não disponível</Message.Header>
            <p style={{marginTop:8,fontSize:13,lineHeight:'17px'}}>O horário para seleção começa toda terça-feira às 14 horas do horário de Brasília e vai até 16 horas de quarta.</p>
          </Message>
        }

        { rooms.map((r) => {
          let segmentConflict = false;
          let qc = 5;

          if (this.props.userData.role !== IDs.Admin && this.props.programmingOrder) {
            const additionalData = JSON.parse(this.props.programmingOrder.additional_data);
            const qu = this.state.quota_usage;

            for (const u of qu) {
              if (u.room === r.room) {
                qc--;

                for (const s of (additionalData.segments||[])) {
                  if (this.props.programmingOrder.advertiser_id !== u.entity_id && u.segments.indexOf(s) !== -1) {
                    segmentConflict = true;
                    break;
                  }
                }
              }
            }
          }


          let available = qc > 0;
          let selected = this.state.programming_select && this.state.programming_select.room === r.room;

          let style = {};
          if (this.state.programming_select && !selected) style = { opacity: 0.8 };
          if (!selected && (!available || segmentConflict)) style = { opacity: 0.2 };

          return (
            <div key={r.room} style={style}>
              { available && !selected && <h3>{ r.room }</h3> }
              { !available && !selected && <h3 style={{backgroundColor:'#222',color:'#FFF'}}>{ r.room } <span style={{fontSize:12}}>(indisponível - lotação completa)</span></h3> }
              { available && !selected && segmentConflict && <h3 style={{backgroundColor:'#444',color:'#FFF'}}>{ r.room } <span style={{fontSize:12}}>(indisponível - conflito de segmentação)</span></h3> }
              { selected && <h3 style={{backgroundColor:'#a333c8',color:'#FFF'}}>{ r.room } <span style={{fontSize:12}}>(selecionada)</span></h3> }

              { r.movies.map((m) => {
                return (
                  <div key={m.movie.name} style={{minHeight:140}}>
                    <figure className="poster" style={{filter:style.opacity===0.2?'grayscale(100%)':null,backgroundImage:m.poster?`url(https://image.tmdb.org/t/p/w300${m.poster})`:'url(/imgs/no-poster.jpg)'}} />

                    <h4>{ m.movie.classification ? `[${m.movie.classification}]` : null} { m.movie.name }</h4>
                    { m.days.map((d) => {
                      return (
                        <div key={d.day}>
                          <h5>{ moment(d.day).format('ddd') }<br/>{ moment(d.day).format('DD/MMM') }</h5>
                          { d.hours.map((h) => {
                            return <p key={h}>{ h }</p>
                          })}
                        </div>
                      );
                    })}
                  </div>
                )
              })}
              { available && !segmentConflict && this.state.programmingSelectPeriod && this.state.programming_select.room !== r.room && <Button floated='right' size='tiny' color='purple' style={{position:'relative',top:-30}} onClick={() => this.selectRoom(r.room)}>{this.state.programming_select.room ? 'Trocar para' : 'Selecionar' } <b>{ r.room }</b></Button> }
            </div>
          );
        })}
      </div>
    );
  }

  // MULTIPLES LOCATIONS
  productHeader(product) {
    const startDateStr = product.startDate.format('DD/MM/YYYY');
    const endDateStr = product.endDate.format('DD/MM/YYYY');

    const quotasStr = `${product.quotas} ${product.quotas < 2 ? 'cota' : 'cotas'}`;

    if (this.props.programmingSelect)
      return `${quotasStr} de ${Math.round(product.quotaSeconds())}s - ${startDateStr} a ${endDateStr}`;
    else
      return `${product.name.toUpperCase()} - ${quotasStr} de ${Math.round(product.quotaSeconds())}s - ${startDateStr} a ${endDateStr}`;
  }

  mediasOptions(product, remainingSeconds) {
    let ret = [{key:'none',text:'Selecione uma mídia'}];

    for (let media of this.state.medias || []) {
      if (media.compatible_with.indexOf(product.id) === -1) continue;
      if (media.status !== 1) continue;

      ret.push({
        key: media.id,
        value: media.id,
        text: `${media.name} (${Math.round(media.length)}s)`,
        disabled: media.length > remainingSeconds
      });
    }

    return ret;
  }

  getMedia(id) {
    for (let media of this.state.medias || []) {
      if (media.id === id) return media;
    }

    return null;
  }

  render() {
    if (this.props.programmingSelect) {
      return (
        <div className='OrderQuotaEditor'>
          <Visibility onOnScreen={this.startupCode.bind(this)} fireOnMount>
            <Dimmer inverted active={!this.state.medias} ><Loader /></Dimmer>
            <div>
              { this.state.products.map((e, i) => {
                if (e.id !== IDs.Preshow && e.id !== IDs.Videowall && e.id !== IDs.Bomboniere && e.id !== IDs.Foyer) return null;

                let isBefore = moment(e.startDate).subtract(3, 'days').startOf('day').isAfter(moment());
                let isAfter = moment(e.endDate).endOf('day').isBefore(moment());

                if (e.id !== IDs.Preshow || isBefore || isAfter)
                  return null;

                const theList = e.theaters.filter(t => t.id === this.props.programmingOrder.theater_id);
                if (theList.length === 0) return null;

                return (
                  <div key={i} style={{paddingTop:8,borderTop:'1px solid #EEE'}}>
                    <List divided>
                      { theList.map((t, j) => {
                        let _selections = this.state.selections[e.parsedObject.idx];
                        let selections = [];

                        for (let selection of _selections || []) {
                          if (selection.theater_id !== t.id || !this.getMedia(selection.media_id)) continue;
                          selections.push(selection);
                        }

                        let remainingSeconds = e.quotaTotalSeconds();
                        remainingSeconds *= 1.2; // 20% margin

                        for (let s of selections || []) {
                          const media = this.getMedia(s.media_id);
                          if (media) remainingSeconds -= media.length;
                        }

                        return (
                          <List.Item key={j}>
                            <List.Content floated='right' style={{minWidth:360}}>
                              <div style={{marginBottom:6}}>
                                <Button disabled={(remainingSeconds<8)||this.state.attaching} primary size='mini' icon='plus' content='Mídia' floated='right' onClick={()=>this.addMedia(e, t, remainingSeconds)} />
                                <div className='left-info'>
                                  { selections.length > 0 && `${selections.length} ${plurarify(selections.length, 'mídia anexada', 'mídias anexadas')}` }
                                  { selections.length === 0 && 'Nenhuma mídia anexada' }
                                  <br/>
                                  { Math.round(remainingSeconds) }s restante
                                </div>
                              </div>
                              { this.state.add_idx === e.parsedObject.idx && this.state.add_theater === t.id &&
                                <div style={{margin:'16px 0'}}>
                                  <Select upward fluid placeholder='Selecione uma mídia' options={this.mediasOptions(e, remainingSeconds)} onChange={(ev, { value })=>this.onMediaChange(e, t, value)} />
                                </div>
                              }
                              { selections && selections.map((s, i) => {
                                const media = this.getMedia(s.media_id);
                                if (!media) return null;

                                return (
                                  <div key={i} style={{marginTop:6}}>
                                    <Button negative size='mini' icon='trash' floated='right' onClick={() => this.removeAttachment(s)} />
                                    { e.id === IDs.Preshow && <Button style={{minWidth:135}} color={s.room?'olive':'orange'} size='mini' icon={s.room?(this.state.programmingSelectPeriod?'pencil':'eye'):'warning circle'} content={s.room?(this.state.programmingSelectPeriod?s.room:'Programação'):'Selecionar sala'} floated='right' onClick={() => this.showProgrammingSelector(s) } /> }
                                    <div style={{textAlign:'right',lineHeight:'28px',overflow:'hidden'}}>
                                      { media.name } ({ Math.round(media.length) }s)
                                    </div>
                                  </div>
                                );
                              }) }
                            </List.Content>

                            <List.Content>
                              <List.Header style={{fontWeight:600}}><a href={`/pedidos/${this.props.programmingOrder.order_id}`} target='_blank' rel='noopener noreferrer'>#{this.props.programmingOrder.order_id} - {this.props.programmingOrder.advertiser}</a></List.Header>
                              <List.Description>{ this.productHeader(e) }</List.Description>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </div>
                );
              })}
            </div>

            <Modal size='small' open={!!this.state.programming_select} closeOnDimmerClick onClose={()=>this.setState({ programming_select: undefined })}>
              <Modal.Content>
                <h3>Seleção da sala</h3>
                { this.renderProgramming() }
              </Modal.Content>
              <Modal.Actions>
                <Button negative content='Fechar' onClick={()=>this.setState({ programming_select: undefined })} />
              </Modal.Actions>
            </Modal>
          </Visibility>
        </div>
      );
    } else {
      return (
        <div className='OrderQuotaEditor'>
          <Visibility onOnScreen={this.startupCode.bind(this)} fireOnMount>
            <Dimmer inverted active={!this.state.medias} ><Loader /></Dimmer>
            <Accordion styled fluid>
              { this.state.products.map((e, i) => {
                if (e.id !== IDs.Preshow && e.id !== IDs.Videowall && e.id !== IDs.Bomboniere && e.id !== IDs.Foyer) return null;

                let isBefore = moment(e.startDate).startOf('day').isAfter(moment());
                let isAfter = moment(e.endDate).endOf('day').isBefore(moment());

                return [
                  <Accordion.Title key={`title_${i}`} style={{color:'#777'}} active={this.state.activeIndex === i} onClick={()=>this.setState({ activeIndex: this.state.activeIndex === i ? -1 : i })}>
                    <Icon name='dropdown' />
                    { this.productHeader(e) }
                    { isBefore && <span style={{color:'#BB4',fontSize:10,fontWeight:600}}>&nbsp;(VEICULAÇÃO NÃO INICIADA)</span> }
                    { !isBefore && !isAfter && <span style={{color:'#7B7',fontSize:10,fontWeight:600}}>&nbsp;(EM VEICULAÇÃO)</span> }
                    { isAfter && <span style={{color:'#77B',fontSize:10,fontWeight:600}}>&nbsp;(VEICULAÇÃO FINALIZADA)</span> }
                  </Accordion.Title>,
                  <Accordion.Content style={{padding:'9px 14px'}} key={`content_${i}`} active={this.state.activeIndex === i}>
                    <List divided>
                      { e.theaters.map((t, j) => {
                        let _selections = this.state.selections[e.parsedObject.idx];
                        let selections = [];

                        for (let selection of _selections || []) {
                          if (selection.theater_id !== t.id || !this.getMedia(selection.media_id)) continue;
                          selections.push(selection);
                        }

                        let remainingSeconds = e.quotaTotalSeconds();
                        remainingSeconds *= 1.2; // 20% margin

                        for (let s of selections || []) {
                          const media = this.getMedia(s.media_id);
                          if (media) remainingSeconds -= media.length;
                        }

                        return (
                          <List.Item key={j}>
                            <List.Content floated='right' style={{minWidth:360}}>
                              <div style={{marginBottom:6}}>
                                <Button disabled={(remainingSeconds<8)||this.state.attaching||isAfter} primary size='mini' icon='plus' content='Mídia' floated='right' onClick={()=>this.addMedia(e, t, remainingSeconds)} />
                                <div className='left-info'>
                                  { selections.length > 0 && `${selections.length} ${plurarify(selections.length, 'mídia anexada', 'mídias anexadas')}` }
                                  { selections.length === 0 && 'Nenhuma mídia anexada' }
                                  <br/>
                                  { Math.round(remainingSeconds) }s restante
                                </div>
                              </div>
                              { this.state.add_idx === e.parsedObject.idx && this.state.add_theater === t.id &&
                                <div style={{margin:'16px 0'}}>
                                  <Select upward fluid placeholder='Selecione uma mídia' options={this.mediasOptions(e, remainingSeconds)} onChange={(ev, { value })=>this.onMediaChange(e, t, value)} />
                                </div>
                              }
                              { selections && selections.map((s, i) => {
                                const media = this.getMedia(s.media_id);
                                if (!media) return null;

                                return (
                                  <div key={i} style={{marginTop:6}}>
                                    <Button negative size='mini' icon='trash' floated='right' onClick={() => this.removeAttachment(s)} />
                                    { !isAfter && e.id === IDs.Preshow && <Button style={{minWidth:135}} color={s.room?'olive':'orange'} size='mini' icon={s.room?(this.state.programmingSelectPeriod?'pencil':'eye'):'warning circle'} content={s.room?(this.state.programmingSelectPeriod?s.room:'Programação'):'Selecionar sala'} floated='right' onClick={() => this.showProgrammingSelector(s) } /> }
                                    <div style={{textAlign:'right',lineHeight:'28px',overflow:'hidden'}}>
                                      { media.name } ({ Math.round(media.length) }s)
                                    </div>
                                  </div>
                                );
                              }) }
                            </List.Content>

                            <List.Content>
                              <List.Header>{t.name}</List.Header>
                              <List.Description>{t.additional_data.shopping}</List.Description>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </Accordion.Content>
                ];
              })}
            </Accordion>

            <Modal size='small' open={!!this.state.programming_select} closeOnDimmerClick onClose={()=>this.setState({ programming_select: undefined })}>
              <Modal.Content>
                <h3>Seleção da sala</h3>
                { this.renderProgramming() }
              </Modal.Content>
              <Modal.Actions>
                <Button negative content='Fechar' onClick={()=>this.setState({ programming_select: undefined })} />
              </Modal.Actions>
            </Modal>
          </Visibility>
        </div>
      );
    }
  }
};
