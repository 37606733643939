import React, { Component } from 'react';

//import moment from 'moment';

// import { toast } from 'react-toastify';

import { Grid } from 'semantic-ui-react';
// import { Icon, Grid, Message, Header } from 'semantic-ui-react';
import ContentHeader from '../components/ContentHeader';
import TitledBlock from '../components/TitledBlock';
//import StatisticBlock from '../components/StatisticBlock';
import MovieList from '../components/Movies/MovieList';

export default class Central extends Component {
  /*state = {
    requisited: [],
    loading: false
  }

  handleCourtesyRequest = async (id) => {
    try {
      this.setState({ loading: true });
    } catch (err) {

    } finally {
      this.setState({ loading: false, requisited: this.state.requisited.concat(id) });
    }

    const content = (
      <div>
        <Header as='h5' style={{margin:'6px 0 6px 0',color:'#FFF'}}><Icon name='info circle' />Cortesia requisitada</Header>
        <p>Recebemos sua requisição e entraremos em contato o quanto antes com as informações para retirada no cinema.</p>
      </div>
    );

    toast.info(content, { autoClose: false, closeButton: true });
  }*/

  render() {
    //const currentMonth = moment().subtract(1, 'month').format('MMMM [de] YYYY');

    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} />

        {/* this.props.userData.refs && this.props.userData.refs.map((e) => {
          if (this.state.requisited.indexOf(e.id) !== -1)
            return null;

          let additionalData = null;

          try {
            additionalData = JSON.parse(e.additional_data);
          } catch (err) {
            additionalData = null;
          }

          if (!additionalData)
            return null;
          return (
            <Grid.Row key={e.id} className='content-margin'>
              <Grid.Column>
                <Message info icon>
                  { this.state.loading && <Icon name='circle notched' loading /> }
                  { !this.state.loading && <Icon name='info circle' /> }
                  <Message.Content>
                    <Message.Header>Cortesia disponível!</Message.Header>
                    <p>Existe uma cortesia disponível para <b>{ this.props.userData.name }</b>. Para resgatar <b style={{cursor:'pointer'}} onClick={() => this.handleCourtesyRequest(e.id)}><u>clique aqui</u></b> e entraremos em contato.</p>
                  </Message.Content>
                </Message>
              </Grid.Column>
            </Grid.Row>
          );
        })*/}

        {/* (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid || this.props.userData.role === IDs.Exhibitor) &&
          <Grid.Row className='content-margin'>
            <StatisticBlock header='Público do Cinema' title={currentMonth} value='N/A' info={<span>Mês anterior: <b>N/A</b></span>} icon='icone-grupo-circular' />
            <StatisticBlock header='Clientes Ativos' title={currentMonth} value='N/A' info={<span>Mês anterior: <b>N/A</b></span>} icon='icone-grupo-executivo' />
            <StatisticBlock header='Cotas Utilizadas' title={currentMonth} value='N/A' info={<span>Mês anterior: <b>N/A</b></span>} icon='icone-video-exibe' />
            <StatisticBlock header='Cotas Disponíveis' title={currentMonth} value='N/A' info={<span>Mês anterior: <b>N/A</b></span>} icon='icone-video-exibe' />
          </Grid.Row>
        */}

        <TitledBlock title='filmes em exibição'>
          <MovieList type='now' limit={16} userData={this.props.userData} />
        </TitledBlock>

        <TitledBlock title='próximos lançamentos'>
          <MovieList type='soon' limit={16} userData={this.props.userData} />
        </TitledBlock>
      </Grid>
    );
  }
}
