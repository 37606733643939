import React from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import 'moment/locale/pt-br.js';

import * as serviceWorker from './serviceWorker';

// App
import App from './App';
import './index.css';

import '../node_modules/dropzone/dist/min/dropzone.min.css';
import '../node_modules/react-dropzone-component/styles/filepicker.css';

import { CS_API_PATH, MS_API_PATH } from './links';

import ReactGA from 'react-ga';

moment.locale('pt-BR');

function __get(apiPath, path, query) {
  return new Promise(async (resolve, reject) => {
    let x_userid = await localforage.getItem('user_id');
    let x_token = await localforage.getItem('token');

    axios.get(apiPath + path, {
      params: query ? query : null,
      headers: x_userid && x_token ? { 'x-userid': x_userid, 'x-token': x_token } : null
    }).then(resolve).catch(reject);
  });
}

function __post(apiPath, path, body) {
  return new Promise(async (resolve, reject) => {
    let x_userid = await localforage.getItem('user_id');
    let x_token = await localforage.getItem('token');

    axios.post(apiPath + path, body, {
      headers: x_userid && x_token ? { 'x-userid': x_userid, 'x-token': x_token } : null
    }).then(resolve).catch(reject);
  });
}

function __delete(apiPath, path, query) {
  return new Promise(async (resolve, reject) => {
    let x_userid = await localforage.getItem('user_id');
    let x_token = await localforage.getItem('token');

    axios.delete(apiPath + path, {
      params: query ? query : null,
      headers: x_userid && x_token ? { 'x-userid': x_userid, 'x-token': x_token } : null
    }).then(resolve).catch(reject);
  });
}

window.cs_get = (path, query) => {
  return __get(CS_API_PATH, path, query);
}

window.cs_post = (path, body) => {
  return __post(CS_API_PATH, path, body);
}

window.cs_delete = (path, query) => {
  return __delete(CS_API_PATH, path, query);
}

window.ms_get = (path, query) => {
  return __get(MS_API_PATH, path, query);
}

window.ms_post = (path, body) => {
  return __post(MS_API_PATH, path, body);
}

window.ms_delete = (path, query) => {
  return __delete(MS_API_PATH, path, query);
}

window.ga_event = (userData, obj) => {
  if (process.env.NODE_ENV === 'production' && userData.role > 2) {
    ReactGA.event(obj);
  }
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-127410251-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
