import moment from 'moment';

import IDs from "unid-core/lib/utils/IDs";
import { parseProductsFromOrder } from 'unid-core/lib/utils/products';
import { getNetworkById, getTheaterById } from 'unid-core/lib/Theaters';
import { formatCurrency } from 'unid-core/lib/utils/formatter';

export function getCutsInfo(order, nfses, installmentNumber, installmentValue, networkCutValue, simulate = false) {
  let total = order.total;

  let networksTotalCut = 0;
  for (let cut of order.cuts) {
    if (cut.type === IDs.Cuts.Network)
    networksTotalCut += cut.cut;
  }

  let agencyTotalCut = 0;
  for (let cut of order.cuts) {
    if (cut.type === IDs.Cuts.Agency)
    agencyTotalCut += cut.cut;
  }

  // Subtrai os valores pagos
  let networksTotalCutPaid = 0;
  for (let payment of nfses) {
    networksTotalCutPaid += payment.networks_cut;
    networksTotalCut -= payment.networks_cut;
    agencyTotalCut -= payment.agency_cut;
    total -= payment.value;
  }

  // Para cada parcela que já possui nota, subtrai a diferença do valor gerado e do total para calcular os repasses subsequintes corretamente
  for (let i = 0; i < order.installments_details.length; i++) {
    // Assume que a parcela não possui NF
    let generated = undefined;

    for (let payment of nfses) {
      if (payment.installment_idx !== i) continue;
      if (generated === undefined) generated = 0;
      // Soma o valor da nota
      generated += payment.value;
    }

    // Se possui algo gerado, subtrai a diferença
    if (generated) total -= order.installments_details[i].price - generated;
  }

  let networksCutRatio =  networksTotalCut / total;
  let agencyCutRatio = agencyTotalCut / total;
  let ret = [];

  for (let i = 0; i < order.installments_details.length; i++) {
    const installment = order.installments_details[i];

    // Soma todos os valores já repassados da parcela
    let networksCutPaid = undefined;
    let agencyCutPaid = undefined;
    let installmentTotal = 0;

    for (let payment of nfses) {
      if (payment.installment_idx === i) {
        if (networksCutPaid === undefined) networksCutPaid = 0;
        if (agencyCutPaid === undefined) agencyCutPaid = 0;
        networksCutPaid += payment.networks_cut;
        agencyCutPaid += payment.agency_cut;
        installmentTotal += payment.value;
      }
    }

    const agencyCut  =  !(simulate && i === installmentNumber) && agencyCutPaid   !== undefined ? agencyCutPaid   : (simulate && i === installmentNumber ? installmentValue : installment.price) * agencyCutRatio;
    const networksCut = !(simulate && i === installmentNumber) && networksCutPaid !== undefined ? networksCutPaid : (simulate && i === installmentNumber ? networkCutValue : networksCutRatio * installment.price);

    const installmentCutRatio = networksCut / (networksTotalCut + networksTotalCutPaid);

    let networksCuts = [];
    for (let cut of order.cuts) {
      if (cut.type === IDs.Cuts.Network)
      networksCuts.push({ cut: installmentCutRatio * cut.cut, network: cut.entity_id });
    }

    ret.push({ agencyCut, networksCut, networksCuts, finalized: installmentTotal === installment.price });
  }

  return ret;
}

export function getNfText(order, nfses, taxes, installmentNumber, installmentValue, networkCutValue) {
  const noCut = false;

  const installment = order.installments_details[installmentNumber];
  const cuts = getCutsInfo(order, nfses, installmentNumber, installmentValue, networkCutValue, true)[installmentNumber];

  let value = installmentValue;

  if (noCut) {
    value -= cuts.agencyCut;
    for (let cut of cuts.networksCuts)
      if (cut.cut) value -= cut.cut;
  }

  let placeInfo = '';
  let paymentInfo = `Forma de pagamento: ${order.installments} parcelas no valor de ${formatCurrency(order.total / order.installments)} cada, com vencimento em ${moment(order.installments_details[0].date).format('DD/MM/YYYY')} a ${moment(order.installments_details[order.installments-1].date).format('DD/MM/YYYY')}.`;
  let installmentInfo = `NOTA FISCAL REFERENTE${noCut?' AO PERCENTUAL DA UNID\n':' À '}${installmentNumber+1}ª PARCELA (${moment(installment.date).format('DD/MM/YYYY')}) = ${formatCurrency(value)}`;

  const products = parseProductsFromOrder(order);

  for (let product of products) {
    for (let theater of product.theaters) {
      let shopping = theater.additional_data['shopping']||'';
      placeInfo += `${placeInfo?', ':''}${shopping?shopping+' - ':''}${theater.city}/${theater.uf}`;
    }
  }

  let cutsInfo = '';
  if (cuts.agencyCut)
    cutsInfo += `Comissão Agência: ${formatCurrency(cuts.agencyCut)}\n`;
  for (let cut of cuts.networksCuts)
    if (cut.cut && !noCut)
      cutsInfo += `Repasse para ${getNetworkById(cut.network) ? getNetworkById(cut.network).name : getTheaterById(cut.network).name}: ${formatCurrency(cut.cut)}\n`;

  let taxesStr = '18%';

  const {municipalTax, federalTax} = taxes;

  if (municipalTax && federalTax) {
    const municipalTaxValue = value * (parseFloat(municipalTax) / 100);
    const federalTaxValue = value * (parseFloat(federalTax) / 100);
    taxesStr = `${formatCurrency(federalTaxValue)} federal e ${formatCurrency(municipalTaxValue)} municipal`;
  }

  return `NOTA FISCAL REFERENTE À COMERCIALIZAÇÃO DE ESPAÇO PUBLICITÁRIO\n\nLocal: ${placeInfo}\n\nID ${order.id}\nTOTAL DO INVESTIMENTO: ${formatCurrency(order.total)}\n\n${paymentInfo}\n\n${cutsInfo?`${cutsInfo}\n`:''}${installmentInfo}\n\nDe acordo com a regulamentação sobre a intermediação e prestação de serviços e acessórios, a base de cálculo do ISS é o preço do serviço, como tal considerada a receita bruta a ele correspondente, sem nenhuma dedução, excetuados os descontos ou abatimentos concedidos independentemente de qualquer condição, portanto, a Nota Fiscal emitida será no valor total com a dedução do percentual de repasse para o Cinema e/ou Agência de Publicidade.\n\nTributos aproximado: ${taxesStr}\n*** Fonte IBPT`;
}