import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import NotFound from './pages/NotFound';

import Central from './pages/Central';
import NewOrder from './pages/NewOrder/NewOrder';
import Order from './pages/Order';
import FinancialAdmin from './pages/Financial/FinancialAdmin';
import ProgrammingAdmin from './pages/Programming/ProgrammingAdmin';
import Charts from './pages/Financial/Charts';
import Movies from './pages/Movies';
//import SimpleBilling from './pages/Financial/SimpleBilling';
import MediaApproval from './pages/MediaApproval';

import Orders from './pages/Orders';
import Entries from './pages/Entries/Entries';
import Players from './pages/Players';
import Player from './pages/Player';

//import TmpFixOrders from './pages/TmpFixOrders';

import IDs from 'unid-core/lib/utils/IDs';

let centralName = '';
let infoName = [''];
let userRoleName = '';

function GetRoutesList(userData) {
  let routeList = [
    { url: '/', icon: '??', text: '??', component: <Central userData={userData} /> }
    /*{ url: '/financeiro', icon: 'icone-dinheiro', text: 'Financeiro', component: <SimpleBilling /> },*/
  ];

  const orderRoutes = [
    { url: '/pedidos', icon: 'icone-carrinho-compras', text: 'Pedidos', component: <Orders type={IDs.PI} userData={userData} /> },
    { url: '/propostas', icon: 'icone-pedido', text: 'Propostas', component: <Orders type={IDs.Proposal} userData={userData} /> },
    { url: '/pedidos/:id', component: <Order userData={userData} /> },
  ];

  const newOrderRoutes = [
    { url: '/compra-online', component: <NewOrder userData={userData} /> },
    { url: '/compra-online/:id', component: <NewOrder userData={userData} /> }
  ];

  const playersRoutes = [
    { url: '/telas', icon: 'icone-play', text: 'Players', component: <Players userData={userData} /> },
    { url: '/telas/:name', component: <Player userData={userData} /> }
  ];

  const moviesRoutes = [
    { url: '/filmes', icon: 'icone-fita-video', text: 'Filmes em cartaz', component: <Movies userData={userData} type='now' /> },
    { url: '/lançamentos', icon: 'icone-estrela', text: 'Próximos lançamentos', component: <Movies userData={userData} type='soon' /> }
  ]

  if (userData.role === IDs.Admin) {
    userRoleName = 'Administrador';
    centralName = 'Central da UNID';
    infoName = ['Administrador'];
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(newOrderRoutes);
    routeList = routeList.concat(orderRoutes);
    routeList = routeList.concat(playersRoutes);

    routeList = routeList.concat([
      { url: '/financeiro', icon: 'icone-dinheiro', text: 'Financeiro', component: <FinancialAdmin userData={userData} /> },
      { url: '/programação', icon: 'icone-calendario', text: 'Programação', component: <ProgrammingAdmin userData={userData} /> },
      { url: '/relatorios', icon: 'icone-grafico-barras', text: 'Relatórios', component: <Charts userData={userData} /> },
      { url: '/cadastro', icon: 'icone-cadastro', text: 'Cadastros', component: <Entries userData={userData} /> },
      //{ url: '/fix_orders', component: <TmpFixOrders userData={userData} type={1} /> },
      //{ url: '/fix_proposals', component: <TmpFixOrders userData={userData} type={2} /> },
      { url: '/mídias-aprovação', component: <MediaApproval userData={userData} /> }
    ]);
  } else if (userData.role === IDs.Unid) {
    userRoleName = 'Equipe UNID';
    centralName = 'Central da UNID';
    infoName = ['Equipe UNID'];
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(newOrderRoutes);
    routeList = routeList.concat(orderRoutes);
    routeList = routeList.concat(playersRoutes);

    routeList = routeList.concat([
      { url: '/programação', icon: 'icone-calendario', text: 'Programação', component: <ProgrammingAdmin userData={userData} /> },
      { url: '/cadastro', icon: 'icone-cadastro', text: 'Cadastros', component: <Entries userData={userData} /> },
      //{ url: '/fix_orders', component: <TmpFixOrders userData={userData} type={1} /> },
      //{ url: '/fix_proposals', component: <TmpFixOrders userData={userData} type={2} /> }
    ]);
  } else if (userData.role === IDs.Exhibitor) {
    userRoleName = 'Exibidor';
    centralName = 'Central do Exibidor';
    infoName = []; for (let ref of userData.refs) infoName.push(ref.name);
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(orderRoutes);

    routeList = routeList.concat([
      { url: '/relatorios', icon: 'icone-grafico-barras', text: 'Relatórios', component: <Charts userData={userData} /> },
    ]);
  } else if (userData.role === IDs.Manager) {
    userRoleName = 'Gerente';
    centralName = 'Central do Gerente';
    infoName = []; for (let ref of userData.refs) infoName.push(ref.name);
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat([
      { url: '/compra-online', component: <NewOrder userData={userData} /> },
      { url: '/programação', icon: 'icone-calendario', text: 'Programação', component: <ProgrammingAdmin userData={userData} /> },
    ]);
  } else if (userData.role === IDs.Marketing) {
    userRoleName = 'Marketing';
    centralName = 'Central do Marketing';
    infoName = []; for (let ref of userData.refs) infoName.push(ref.name);
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(playersRoutes);
  } else if (userData.role === IDs.Advertiser) {
    userRoleName = 'Anunciante';
    centralName = 'Central do Anunciante';
    infoName = []; for (let ref of userData.refs) infoName.push(ref.name);
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(newOrderRoutes);
    routeList = routeList.concat(orderRoutes);
    routeList = routeList.concat([
      { url: '/programação', icon: 'icone-calendario', text: 'Programação', component: <ProgrammingAdmin userData={userData} /> }
    ]);
  } else if (userData.role === IDs.Agency) {
    userRoleName = 'Agência';
    centralName = 'Central da Agência';
    infoName = []; for (let ref of userData.refs) infoName.push(ref.name);
    routeList[0].icon = 'icone-unid';

    routeList = routeList.concat(newOrderRoutes);
    routeList = routeList.concat(orderRoutes);
    routeList = routeList.concat([
      { url: '/programação', icon: 'icone-calendario', text: 'Programação', component: <ProgrammingAdmin userData={userData} /> }
    ]);
  }

  routeList = routeList.concat(moviesRoutes);

  routeList[0].text = centralName;
  return routeList;
}

export function GetCentralName() {
  return centralName;
}

export function GetInfoName() {
  return infoName;
}

export function GetUserRoleName() {
  return userRoleName;
}

export function GetRoutes(userData, _this) {
  let menu = GetRoutesList(userData);

  return (
    <Switch>
      {menu.map((e) => {
        if (!e.url) return null; // FIXME (ainda é necessário?)

        return (
          <Route key={e.url} exact path={e.url}>
          { () => {
            if (_this.state.currentPath !== e.url) {
              setTimeout(() => {
                _this.setState({ currentPath: e.url });
              }, 1);
            }
            return e.component;
          }}
          </Route>
        );
      })}
      <Route key="404" path="/" component={NotFound} userData={userData} />
    </Switch>
  );
}

export function GetMenu(userData, _this) {
  let menu = GetRoutesList(userData);

  return (
    <div>
      {menu.map((e) => {
        if (!e.text) return null;
        return (
          <Menu.Item color={'blue'} active={_this.state.currentPath === '/' ? e.url === _this.state.currentPath : e.url.startsWith(_this.state.currentPath)} key={e.url} as={Link} to={e.url}>
            <span className={e.icon}></span>
            {e.text}
          </Menu.Item>
        );
      })}
    </div>
  );
}
