import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

import Movie from './Movie';
import MovieViwer from './MovieViwer';

export default class MovieList extends Component {
  state = { lastMovieViwerId: null, movies: [] };

  /**
   * Retorna a cor da certificação
   * @param {String} Certificação  
   */
  movieClassificatonColor(certification) {
    switch(certification) {
      case 'L' : return '#00AF51';
      case '10': return '#00CCFF';
      case '12': return '#FFCC00';
      case '14': return '#FF6600';
      case '16': return '#FE0000';
      case '18': return '#000000';
      default  : return '#00AF51';
    }
  }

  async toggleBlockbuster(movie) {
    let movies = [ ...this.state.movies ];

    for (let m of movies) {
      if (m.id === movie.id) {
        m.blockbuster = m.blockbuster === 0 ? 1 : 0;
      }
    }

    this.setState({ movies: movies });

    try {
      await window.cs_post(`/movies/${movie.id}/blockbuster`, { blockbuster: movie.blockbuster === 0 ? 1 : 0 });
    } catch(err) {
      console.error(err);
    }
  }

  /**
   * Cria o array de objetos para renderização
   * A cada 2 filmes é adicionado um visualizador do filme. De acordo com o filme selecionado, irá ser colocado no próximo visualizador que esteja na linha de baixo.
   */
  drawMovies() {
    const movies = this.state.movies;
    
    let ret = [];
    for (let i = 0; i < movies.length; i++) {
      const movie = movies[i];

      if (i !== 0 && i % 2 === 0) ret.push(<MovieViwer key={`mv_${i}`} ref={`mv_${i}`} toggleBlockbuster={this.toggleBlockbuster.bind(this)} userData={this.props.userData} />);
      ret.push(<Movie key={i} pos={i} movie={movie} onClick={this.onMovieClick.bind(this)} />);
    }

    // Adiciona um movieViwer no final da lista de filmes
    ret.push(<MovieViwer key={`mv_${movies.length}`} ref={`mv_${movies.length}`} toggleBlockbuster={this.toggleBlockbuster.bind(this)} userData={this.props.userData} />);
    return ret;
  }

  onMovieClick(e) {
    const ratio = document.body.clientWidth / e.state.width;
    const moviesPerRow = ratio < 4 ? 2 : (ratio < 8 ? 4 : 8);
    let movieViwerId = (Math.floor(e.props.pos/moviesPerRow)+1) * moviesPerRow;

    if (movieViwerId > this.state.movies.length) movieViwerId = this.state.movies.length;

    if (this.state.lastMovieViwerId && this.state.lastMovieViwerId !== movieViwerId) {
      this.refs[`mv_${this.state.lastMovieViwerId}`].hide();
    }

    if (this.state.lastMovieViwerId && this.refs[`mv_${this.state.lastMovieViwerId}`].state.movie.id === e.props.movie.id) {
      this.refs[`mv_${this.state.lastMovieViwerId}`].hide(true);
      this.setState({ lastMovieViwerId: null });
      return;
    }

    this.refs[`mv_${movieViwerId}`].show(e.props.movie);
    this.setState({ lastMovieViwerId: movieViwerId });
  }

  async componentDidMount() {
    try {
      const res = await window.cs_get('/movies', { type: this.props.type, limit: this.props.limit });
      this.setState({ movies: res.data });
    } catch(err) {
      console.error(err);
      this.setState({ movies: [] });
    }
  }

  render() {
    return (
      <Grid>
        <Grid.Row style={{padding:'14px 8px'}}>
          {this.drawMovies()}
        </Grid.Row>
      </Grid>
    );
  }
}