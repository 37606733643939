import React, { Component } from 'react';

import { Grid } from 'semantic-ui-react';

import ContentHeader from '../components/ContentHeader';
import MovieList from '../components/Movies/MovieList';

export default class Player extends Component {
  render() {
    return (
      <Grid padded className={this.props.type === 'now' ? 'Filmes em cartaz' : 'Próximos lançamentos'}>
        <ContentHeader userData={this.props.userData} path={[this.props.type === 'now' ? 'Filmes em cartaz' : 'Próximos lançamentos']}/>

        <Grid.Row>
          <Grid.Column>
            <MovieList type={this.props.type} userData={this.props.userData} />

            <div style={{marginTop:8}}>
              <a href='https://www.themoviedb.org' target='_blank' rel='noopener noreferrer' style={{float:'left',marginRight:12}}><img src='/imgs/tmdb.svg' height='42' alt='TMDb' /></a>
              <p style={{fontSize:12,lineHeight:'42px'}}>Cortesia de TMDb. Algumas informações podem estar incompletas e/ou incorretas. Consulte sempre a programação oficial do Cinema.</p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}