import React, { Component } from 'react';

import { Grid, Form, Segment, Message, Image, Button } from 'semantic-ui-react';

import { unescape } from 'unid-core/lib/utils/string.js';

import './Login.css';
export default class Login extends Component {
  state = {
    data: {},
    loading: false,
    authFailed: false,
    recoveringPassword: false,
    resetingPassword: false,
    emailSent: false,
    email: '',
    password: '',
    password2: ''
  };

  handleChange(e, { name, value }) {
    let error = false;

    if (name === 'password') {
      let upp = false;
      let low = false;
      let num = false;
      let min = value.length > 8;

      for (let i = 0; i < value.length; i++) {
        const c = value.charCodeAt(i);

        if (c >= 65 && c <= 90)  upp = true;
        if (c >= 97 && c <= 122) low = true;
        if (c >= 48 && c <= 57)  num = true;
      }

      error = !(upp && low && num && min);
    }

    if (name === 'password2' && this.state.password !== value) {
      error = true;
    }

    this.setState({
      [`${name}Error`]: error,
      [`${name}`]: value
    });
  }

  async handleSubmit() {
    if (this.state.recoveringPassword) {
      const emailError = !this.state.email;

      this.setState({
        emailError
      });

      if (!emailError) {
        this.setState({ loading: true, authFailed: false });

        try {
          await window.cs_post('/user/password-recovery', { email: this.state.email });
          this.setState({ loading: false, emailSent: true });
        } catch (err) {
          this.setState({ loading: false, authFailed: true });
        }
      }
    } else if (this.state.resetingPassword) {
      const passwordError = !this.state.password;
      const password2Error = !this.state.password2;

      if (!passwordError && !password2Error) {
        this.setState({ loading: true, authFailed: false });

        try {
          await window.cs_post('/user/reset-password', { q: unescape(window.location.search.replace('?q=', '')), password: this.state.password });
          this.setState({ loading: false, emailSent: true });
        } catch (err) {
          this.setState({ loading: false, authFailed: true });
        }
      }
    } else {
      const emailError = !this.state.email;
      const passwordError = !this.state.password;

      this.setState({
        emailError,
        passwordError
      });

      if (!emailError && !passwordError) {
        this.setState({ loading: true, authFailed: false });

        try {
          const res = await window.cs_post('/user/login', { email: this.state.email, password: this.state.password });
          this.props.onLogin(res.data);
        } catch (err) {
          this.setState({ loading: false, authFailed: true });
        }
      }
    }
  }

  componentDidMount() {
    if (window.location.pathname === '/senha' && window.location.search.startsWith('?q=')) {
      this.setState({ resetingPassword: true });
    }
  }

  render() {
    return (
      <Grid className='Login' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Image src='/imgs/logo.png' style={{margin: '40px auto'}}/>

          <Form size='large' onSubmit={this.handleSubmit.bind(this)}>
            { !this.state.recoveringPassword && !this.state.resetingPassword &&
              <Segment loading={this.state.loading}>
                <Form.Input name="email" fluid icon='user' iconPosition='left' placeholder='E-mail' value={this.state.email} onChange={this.handleChange.bind(this)} />
                <Form.Input name="password" fluid icon='lock' iconPosition='left' placeholder='Senha' type='password' value={this.state.password} onChange={this.handleChange.bind(this)} />
                <Message size="small" negative hidden={!this.state.authFailed} content='Usuário não existe ou senha inválida' />
                { /* eslint-disable-next-line */ }
                <div className='forgot-password'><a onClick={()=>this.setState({ recoveringPassword: true })}>Esqueci a senha</a></div>
                <Button color='blue' fluid size='large'>Entrar</Button>
              </Segment>
            }

            { this.state.recoveringPassword &&
              <Segment loading={this.state.loading}>
                <Form.Input name="email" fluid icon='user' iconPosition='left' placeholder='E-mail' value={this.state.email} onChange={this.handleChange.bind(this)} error={this.state.emailError} />
                <Message size="small" negative hidden={!this.state.authFailed} content='Não foi possível recuperar sua senha' />
                <Message size="small" info hidden={!this.state.emailSent} content='As instruções para redefinição de senha foram enviadas para seu e-mail' />
                { /* eslint-disable-next-line */ }
                <div className='forgot-password'><a onClick={()=>this.setState({ recoveringPassword: false, emailSent: false, authFailed: false })}>Fazer login</a></div>
                <Button color='blue' fluid size='large'>Redefinir senha</Button>
              </Segment>
            }

            { this.state.resetingPassword &&
              <Segment loading={this.state.loading}>
                <Form.Input name="password" fluid icon='lock' iconPosition='left' placeholder='Nova senha' type='password' value={this.state.password} onChange={this.handleChange.bind(this)} error={this.state.passwordError} />
                <Form.Input name="password2" fluid icon='lock' iconPosition='left' placeholder='Confirmar senha' type='password' value={this.state.password2} onChange={this.handleChange.bind(this)} error={this.state.password2Error} />
                <Message size="small" negative hidden={!this.state.passwordError||this.state.password2.length===0} content='A senha deve conter pelo menos 8 caracteres e possuir ao menos uma letra maíscula, uma minúscula e um número' />
                <Message size="small" negative hidden={!this.state.password2Error} content='As senhas não conferem' />
                <Message size="small" info hidden={!this.state.emailSent} content='Agora você pode fazer login com sua nova senha.' />
                <Message size="small" negative hidden={!this.state.authFailed} content='Não foi possível salvar a sua senha' />
                <div className='forgot-password'><a href='/'>Fazer login</a></div>
                <Button color='blue' fluid size='large' disabled={this.state.emailSent || this.state.passwordError || this.state.password2Error || !this.state.password || !this.state.password2}>Salvar senha</Button>
              </Segment>
            }

            <p className="copyright">Copyright © 2019 Unid Cinemídia LTDA. Todos os direitos reservados.</p>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}
