import React, { Component } from 'react';

import moment from 'moment';

import { Input, Header, Statistic, Label, Form, TextArea, Select, Modal, Button, Dropdown, Checkbox, Responsive } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import LocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { plurarify } from 'unid-core/lib/utils/string';

import IDs from 'unid-core/lib/utils/IDs';

import './ProductOptions.css';
export default class ProductOptions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isMobile: window.innerWidth < Responsive.onlyComputer.minWidth,
      comboModal: false,
      quotaType: this.props.product.quotaType,
      quotas: this.props.product.quotas,
      wantedPrice: this.props.product.total(),
      wantedProductPrice: 0,
      discountPercent: 0,
      payLess: this.props.product.discount !== 0 || props.userData.role === IDs.Admin || props.userData.role === IDs.Unid,
      payLessMsg: '',
      priceRatio: 0,
      additionalData: this.props.product.additionalData || {}
    };

    this.resetCustomPrice = this.resetCustomPrice.bind(this);
  }

  resetCustomPrice() {
    const product = this.props.product;
    product.additionalData.customPrice = 0;
    product.discount = 0;
    this.updatePrice();
  }

  handleInput(e, { name, value }) {
    if (name === 'desc' || name === 'period' || name === 'little_combo' || name === 'medium_combo' || name === 'big_combo') {
      if (name === 'little_combo' || name === 'medium_combo' || name === 'big_combo') {
        if (!Number.isNaN(value) && Number.isInteger(Number(value)))
          this.onAdditionalData(name, Number(value));
        else
          this.onAdditionalData(name, 0);
      } else this.onAdditionalData(name, value);
    }
  }

  onQuotaTypeChanged(e, { value }) {
    const product = this.props.product;
    product.quotaType = value;
    this.setState({ quotaType: value });
  }

  onQuotasChanged(e, data) {
    const product = this.props.product;

    if (data.value === '') {
      product.quotas = 1;
      product.discount = 0;
      this.setState({ quotas: '' });
      this.updatePrice();
      return;
    }

    let quotas = parseInt(data.value, 10);

    if (!Number.isNaN(quotas) && Number.isInteger(quotas)) {
      product.quotas = quotas;
      product.discount = 0;
      this.setState({ quotas: quotas });
      this.updatePrice();
    }
  }

  onStartDateChanged(startDate) {
    const product = this.props.product;
    product.resetTheatersPrice();

    startDate = moment(startDate).startOf('day');

    let needEndDateUpdate = false;
    if (product.startDate.isoWeekday() !== startDate.isoWeekday())
      needEndDateUpdate = true;

    let endDate = moment(this.refs.endDate.state.value, 'DD/MM/YYYY');
    product.startDate = startDate;
    product.endDate = endDate;

    if (startDate.isAfter(endDate, 'day') || needEndDateUpdate) {
      let newEndDate = moment(startDate).add(product.id === IDs.Event ? 0 : 6, 'days');
      product.endDate = newEndDate;
      this.refs.endDate.setState({ value: newEndDate.toDate() });
      this.updatePrice();
    } else {
      this.updatePrice();
    }
  }

  onEndDateChanged(endDate) {
    const product = this.props.product;
    product.resetTheatersPrice();

    endDate = moment(endDate).startOf('day');

    product.startDate = moment(this.refs.startDate.state.value, 'DD/MM/YYYY');
    product.endDate = endDate;
    this.updatePrice();
  }

  onAdditionalData(name, value) {
    const product = this.props.product;

    product.additionalData = { ...product.additionalData, [name]: value };
    this.setState({ additionalData: { ...this.state.additionalData, [name]: value }});
    this.updatePrice();
  }

  onWantedPriceChanged(e, { value }) {
    const product = this.props.product;

    if (value.replace(/\.|,/g,'') === '') {
      product.discount = product.totalWithoutDiscount();
      this.setState({ wantedPrice: 0, discountPercent: 100 });
      this.updatePrice();
      return;
    }

    let wantedPrice = parseInt(value.replace(/\.|,/g,''), 10) / 100;

    if (!Number.isNaN(wantedPrice)) {
      if (wantedPrice > product.totalWithoutDiscount()) wantedPrice = product.totalWithoutDiscount();
      product.discount = product.totalWithoutDiscount() - wantedPrice;
      this.setState({ wantedPrice });
      this.updatePrice();
    }
  }

  onWantedProductPriceChanged(e, { value }) {
    const product = this.props.product;

    let wantedProductPrice = ((parseInt(value.replace(/\.|,/g,''), 10)||0)/100)*(this.state.quotaType==='30'?1:2);
    if (wantedProductPrice < 0) wantedProductPrice = 0;

    let wantedPrice = product.totalWithoutDiscount() * (wantedProductPrice / product.price());
    product.discount = product.totalWithoutDiscount() - wantedPrice;

    if (wantedProductPrice > product.price()) {
      product.additionalData.customPrice = wantedProductPrice;
      wantedPrice = wantedProductPrice;
      product.discount = 0;
    }

    this.setState({ wantedPrice, wantedProductPrice });
    this.updatePrice();
  }

  onDiscountPercentChanged(e, { value }) {
    const product = this.props.product;

    let wantedDiscountPercent = (parseInt(value.replace(/,/g,''), 10)||0) / 100;

    if (wantedDiscountPercent < 0) wantedDiscountPercent = 0;
    if (wantedDiscountPercent > 100) wantedDiscountPercent = 100;

    let wantedPrice = product.totalWithoutDiscount() * (1-(wantedDiscountPercent/100));
    product.discount = product.totalWithoutDiscount() - wantedPrice;

    this.setState({ wantedPrice, discountPercent: wantedDiscountPercent });
    this.updatePrice();
  }

  peopleCount() {
    let peopleCount = 0;
    const theaters = this.props.product.theaters;

    for (let theater of theaters) {
      peopleCount += theater.stats.public;
    }

    const months = this.props.product.months(true);
    return Math.round(peopleCount * months);
  }

  setPayless() {
    this.props.product.discount = 0;
    this.setState({ payLess: true, wantedPrice: this.props.product.totalWithoutDiscount() });
    this.updatePrice();

    setTimeout(() => {
      this.refs.wantedPrice.focus();
    }, 250);
  }

  updatePrice() {
    const product = this.props.product;

    let priceRatio = product.total() / (product.totalWithoutDiscount()||1);
    let payLessMsg = '';

    const ratioStr = formatNumber((1-priceRatio) * 100,1);

    if (priceRatio === 1) {
      payLessMsg = '';
    } else if (priceRatio > 0.7) {
      payLessMsg = `Você está solicitando ${ratioStr}% de desconto, existe a possibilidade do desconto ser aprovado`;
    } else if (priceRatio > 0.5) {
      payLessMsg = `Você está solicitando ${ratioStr}% de desconto, iremos verificar a disponibilidade`;
    } else if (priceRatio > 0.2) {
      payLessMsg = `Você está solicitando ${ratioStr}% de desconto, o desconto está acima de nossa política de desconto. Iremos verificar a possibilidade.`;
    } else {
      payLessMsg = `Não trabalhamos com ${ratioStr}% de desconto.`;
    }

    let newWantedPrice = product.totalWithoutDiscount() - product.discount;
    if (newWantedPrice < 0) newWantedPrice = 0;

    this.setState({ payLessMsg, priceRatio, wantedPrice: newWantedPrice, discountPercent: (1-priceRatio) * 100, wantedProductPrice: product.price() * priceRatio });
  }

  selectedTheatersCount() {
    let theaterCount = 0;

    for (let state of this.props.theaters) {
      const theaters = state.getTheaters();
      for (let theater of theaters)
        if (theater.selected) theaterCount++;
    }

    return theaterCount;
  }

  labelWithHint(label, labelHint, big = false) {
    return [
      <span key={1} style={{fontSize:big?'1em':10}}>{label}</span>,
      <br key={2}/>,
      <span key={3} style={{position:'relative',top:-8,fontSize:8,fontWeight:400,textTransform:'none'}}>{labelHint}</span>
    ]
  }

  discountComponent(spaced = false) {
    const product = this.props.product;
    const showMoreOptions = this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid;

    let showResetCustomPriceButton = false;
    if (product.additionalData.customPrice)
      showResetCustomPriceButton = true;

    return (
      <span>
        <Header as='h1' textAlign='center' color='olive' style={{textDecoration:this.state.payLess&&product.discount!==0?'line-through':'none',marginTop:spaced?70:30}}>{formatCurrency(product.totalWithoutDiscount())}</Header>

        { /* eslint-disable-next-line */ }
        { !this.state.payLess && <a onClick={this.setPayless.bind(this)}>{showMoreOptions?'Aplicar desconto':'Quer desconto?'}</a> }

        { this.state.payLess &&
          <div style={{textAlign:'center',margin:'0 10px'}}>
            { (!showMoreOptions && !this.state.payLessMsg) && <Header size='small'>Quanto você deseja pagar?</Header> }
            { (!showMoreOptions && this.state.payLessMsg) && <Header color={this.state.priceRatio>0.7?'olive':(this.state.priceRatio>0.5?'yellow':(this.state.priceRatio>0.2?'orange':'red'))} size='small'>{this.state.payLessMsg}</Header> }

            <Input ref='wantedPrice' size='small' labelPosition='left' type='text' value={formatNumber(this.state.wantedPrice, 2)} onChange={this.onWantedPriceChanged.bind(this)} >
              <Label basic>R$</Label>
              <input style={{textAlign:'center'} }/>
            </Input>
            { showMoreOptions &&
              [
                <br key={0} />,
                <Input key={1} style={{marginTop:6}} size='mini' labelPosition='left' type='text' value={formatNumber(this.state.wantedProductPrice*(this.state.quotaType==='30'?1:0.5), 2)} onChange={this.onWantedProductPriceChanged.bind(this)} >
                  <Label basic>Valor Unitário</Label>
                  <input style={{textAlign:'center',width:100} }/>
                  { showResetCustomPriceButton && <Button style={{marginLeft:4}} icon='redo' size='mini' onClick={this.resetCustomPrice} /> }
                </Input>,
                <br key={2} />,
                <Input key={3} style={{marginTop:6}} size='mini' labelPosition='left' type='text' value={formatNumber(this.state.discountPercent, 2)} onChange={this.onDiscountPercentChanged.bind(this)} >
                  <Label basic>% Desconto</Label>
                  <input style={{textAlign:'center',width:100} }/>
                </Input>,
                <br style={{display:this.props.product.dailyCharged?'none':null}} key={4}/>,
                <Checkbox style={{display:product.id === IDs.CorporateTicket||this.props.product.dailyCharged?'none':null}} key={5} label='Usar preço base semanal' onChange={(e, { checked }) => { product.setUseSimplePricing(checked); this.updatePrice(); }} />
              ]
            }
          </div>
        }
      </span>
    );
  }

  combosInfo() {
    let comboStr = '';

    if (this.state.additionalData.little_combo)
      comboStr += this.state.additionalData.little_combo + plurarify(this.state.additionalData.little_combo, ' combo', ' combos') + plurarify(this.state.additionalData.little_combo, ' pequeno', ' pequenos');

    if (this.state.additionalData.medium_combo)
      comboStr += (comboStr===''?'':', ')+this.state.additionalData.medium_combo + (comboStr===''?plurarify(this.state.additionalData.medium_combo, ' combo', ' combos'):'') + plurarify(this.state.additionalData.medium_combo, ' médio', ' médios');

    if (this.state.additionalData.big_combo)
      comboStr += (comboStr===''?'':', ')+this.state.additionalData.big_combo + (comboStr===''?plurarify(this.state.additionalData.big_combo, ' combo', ' combos'):'') + plurarify(this.state.additionalData.big_combo, ' grande', ' grandes');

    if (comboStr === '') return 'Nenhum combo selecionado';
    return comboStr;
  }

  planSelect(weeks) {
    const product = this.props.product;
    product.resetTheatersPrice();

    product.endDate = moment(product.startDate).add(weeks, 'weeks').subtract(1, 'day');
    this.updatePrice();
  }

  componentDidMount() {
    this.updatePrice();
  }

  render() {
    const product = this.props.product;
    const theaters = product.theaters;

    const days = product.days();
    const weeks = product.weeks();
    const theatersCount = theaters.length;
    const peopleCount = this.peopleCount();
    const insertions = product.totalInsertions();

    let startDateDisabledDays = (date) => {
      date = moment(date);

      if (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid) {
        if (product.id !== IDs.Preshow) return false;

        if(!date || date.isoWeekday() !== 4)
          return true;
        else
          return false;
      }

      if (product.id === IDs.Event) return date.isBefore(moment().add(13, 'days'));
      if (product.id === IDs.PromoAction) return date.isBefore(moment().add(6, 'days'));
      if (product.id !== IDs.Preshow) return date.isBefore(moment());
      if(!date || date.isoWeekday() !== 4)
        return true;
      return date.isBefore(moment());
    }

    let endDateDisabledDays = (date) => {
      date = moment(date);
      if (product.id === IDs.Event || product.id === IDs.PromoAction) return date.isBefore(moment(this.refs.startDate.state.value, 'DD/MM/YYYY'));
      if (product.id === IDs.NamingRights) return date.isBefore(moment(this.refs.startDate.state.value, 'DD/MM/YYYY'));
      if (product.id !== IDs.Preshow) return date.diff(moment(this.refs.startDate.state.value, 'DD/MM/YYYY'), 'days') % 7 !== 6 || date.isBefore(moment(this.refs.startDate.state.value, 'DD/MM/YYYY'));
      if(!date || date.isoWeekday() !== 3)
        return true;
      return date.isBefore(moment(this.refs.startDate.state.value, 'DD/MM/YYYY'));
    }

    const { isMobile } = this.state;
    const productOptionsOverride = isMobile ? {display:'block'} : null;
    const leftPanelOverride = isMobile ? {width:'100%',padding:30} : null;

    if (product.id === IDs.NamingRights) {
      return (
        <div className='ProductOptions' style={productOptionsOverride}>
          <div className='left-panel' style={leftPanelOverride}>
            <Header size='small' style={{margin:'0 0 12px 0'}}>Quantidade de pessoas</Header>
            <Input style={{width:'100%'}} value={this.state.quotas} onChange={this.onQuotasChanged.bind(this)} />

            <Header size='tiny' style={{margin:'20px 0 12px 0'}}>Data inicial</Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="startDate" onDayChange={this.onStartDateChanged.bind(this)} value={moment(product.startDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: startDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>

            <Header size='tiny' style={{margin:'20px 0 12px 0'}}>
              <Dropdown text='Data final'>
                <Dropdown.Menu>
                  <Dropdown.Item text='3 meses' description='13 semanas' onClick={()=>this.planSelect(13)}/>
                  <Dropdown.Item text='6 meses' description='26 semanas' onClick={()=>this.planSelect(26)} />
                  <Dropdown.Item text='12 meses' description='52 semanas' onClick={()=>this.planSelect(52)} />
                </Dropdown.Menu>
              </Dropdown>
            </Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="endDate" onDayChange={this.onEndDateChanged.bind(this)} value={moment(product.endDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: endDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
          <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
            { !isMobile &&
              <div style={{textAlign:'center',marginTop:40}}>
                <Statistic size='tiny' color='blue' value={theatersCount} label={theatersCount>1?'cinemas':'cinema'} />
                <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/(product.quotas*theatersCount))} label={this.labelWithHint('custo por pessoa', 'valor médio', true)} />
              </div>
            }

            { this.discountComponent() }
          </div>
        </div>
      );
    }

    if (product.id === IDs.PromoAction) {
      return (
        <div className='ProductOptions' style={productOptionsOverride}>
          <div className='left-panel' style={leftPanelOverride}>
            <Header size='tiny' style={{margin:'0 0 12px 0'}}>Início da ação</Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="startDate" onDayChange={this.onStartDateChanged.bind(this)} value={moment(product.startDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: startDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>

            <Header size='tiny' style={{margin:'20px 0 12px 0'}}>
              <Dropdown text='Fim da ação'>
                <Dropdown.Menu>
                  <Dropdown.Item text='Plano Trimestral' description='13 semanas' onClick={()=>this.planSelect(13)}/>
                  <Dropdown.Item text='Plano Semestral' description='26 semanas' onClick={()=>this.planSelect(26)} />
                  <Dropdown.Item text='Plano Anual' description='52 semanas' onClick={()=>this.planSelect(52)} />
                </Dropdown.Menu>
              </Dropdown>
            </Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="endDate" onDayChange={this.onEndDateChanged.bind(this)} value={moment(product.endDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: endDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>

            <Header size='small' style={{margin:'20px 0 12px 0'}}>Descrição</Header>
            <Form>
              <TextArea name='desc' value={this.state.additionalData.desc||''} onChange={this.handleInput.bind(this)} />
            </Form>
          </div>
          <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
            { !isMobile &&
              <div style={{textAlign:'center',marginTop:40}}>
                <Statistic size='tiny' color='blue' value={theatersCount} label={theatersCount>1?'cinemas':'cinema'} />
                <Statistic size='tiny' color='blue' value={weeks} label={weeks>1?'semanas':'semana'} />
                <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/(theatersCount*weeks||1))} label={this.labelWithHint('custo semanal', 'valor médio', true)} />
              </div>
            }

            { this.discountComponent(true) }
          </div>
        </div>
      );
    }

    if (product.id === IDs.CorporateTicket) {
      return (
        <div className='ProductOptions' style={productOptionsOverride}>
          <div className='left-panel' style={leftPanelOverride}>
            <Header size='small' style={{margin:'0 0 12px 0'}}>Quantidade de ingressos</Header>
            <Input style={{width:'100%'}} value={this.state.quotas} onChange={this.onQuotasChanged.bind(this)} />
            <p style={{display:this.state.quotas>=50?'none':undefined,color:'#F00',fontSize:12,marginTop:6}}>Número mínimo de ingressos: 50</p>

            <Header size='tiny' style={{margin:'24px 0 12px 0'}}>Rede</Header>
            <Select style={{width:'100%'}} value={1} options={networks}/>
          </div>
          <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
            { !isMobile &&
              <div style={{textAlign:'center',marginTop:40}}>
                <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/product.quotas)} label={this.labelWithHint('custo por ingresso', 'valor médio', true)} />
              </div>
            }

            { this.discountComponent() }

            <p style={{marginTop:48,fontSize:11,textAlign:'center'}}>* Ingressos válidos por 30 dias após a impressão</p>
          </div>
        </div>
      );
    }

    if (product.id === IDs.Event) {
      const periods = [
        { key: 'day', value: 'day', text: 'Manhã' },
        { key: 'night', value: 'night', text: 'Tarde/Noite' }
      ];

      const { little_combo, medium_combo, big_combo } = this.state.additionalData;
      const combos = (little_combo||0) + (medium_combo||0) + (big_combo||0);

      return (
        <div className='ProductOptions' style={productOptionsOverride}>
          <div className='left-panel' style={leftPanelOverride}>
            <Header size='small' style={{margin:'0 0 12px 0'}}>Quantidade de pessoas</Header>
            <Input style={{width:'100%'}} value={this.state.quotas} onChange={this.onQuotasChanged.bind(this)} />
            <p style={{fontSize:12,fontWeight:400,margin:'8px 0 0 1px'}}>{ this.combosInfo() }</p>
            { /* eslint-disable-next-line */ }
            <a style={{fontSize:12,margin:'2 0 0 1px',textAlign:'left'}} onClick={()=>this.setState({comboModal:true})}>Editar combos</a>

            <Header size='tiny' style={{margin:'26px 0 12px 0'}}>Período</Header>
            <Select name='period' style={{width:'100%'}} value={this.state.additionalData.period||'day'} options={periods} onChange={this.handleInput.bind(this)}/>

            <Header size='tiny' style={{margin:'12px 0 12px 0'}}>Início do evento</Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="startDate" onDayChange={this.onStartDateChanged.bind(this)} value={moment(product.startDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: startDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>

            <Header size='tiny' style={{margin:'12px 0 12px 0'}}>Fim do evento</Header>
            <Form>
              <Form.Group>
                <div className='field'>
                  <div className='ui left icon input'>
                    <i aria-hidden="true" className="calendar icon"></i>
                    <DayPickerInput ref="endDate" onDayChange={this.onEndDateChanged.bind(this)} value={moment(product.endDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: endDateDisabledDays }} />
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
          <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
            { !isMobile &&
              <div style={{textAlign:'center',marginTop:40}}>
                <Statistic size='tiny' color='blue' value={theatersCount} label={theatersCount>1?'cinemas':'cinema'} />
                <Statistic size='tiny' color='blue' value={days} label={days>1?'dias':'dia'} />
                <Statistic size='tiny' color='blue' value={combos} label={this.labelWithHint(plurarify(combos, 'combo', 'combos'), theatersCount>1?'por cinema':'', true)} />
              </div>
            }

            { !isMobile &&
              <div className='smaller' style={{textAlign:'center',marginTop:8}}>
                <Statistic size='tiny' color='olive' value={formatCurrency((product.total_combos()/(combos||1))*(product.total()/(product.totalWithoutDiscount()||1)))} label={this.labelWithHint('custo do combo', 'valor médio', true)} />
                <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/(product.quotas*theatersCount))} label={this.labelWithHint('custo por pessoa', 'valor médio', true)} />
              </div>
            }

            { this.discountComponent(true) }
          </div>

          <Modal open={this.state.comboModal} size='mini'>
            <Modal.Header>Quantidade de combos</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input name='little_combo' label='Pequeno (R$ 9,50)' value={this.state.additionalData.little_combo||0} onChange={this.handleInput.bind(this)} />
                <Form.Input name='medium_combo' label='Médio (R$ 12,50)' value={this.state.additionalData.medium_combo||0} onChange={this.handleInput.bind(this)} />
                <Form.Input name='big_combo' label='Grande (R$ 14,00)' value={this.state.additionalData.big_combo||0} onChange={this.handleInput.bind(this)} />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button positive content='Salvar' onClick={()=>this.setState({comboModal:false})} />
            </Modal.Actions>
          </Modal>
        </div>
      );
    }

    if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics) {
      return (
        <div className='ProductOptions' style={productOptionsOverride}>
          <div className='left-panel' style={leftPanelOverride}>
            <Header size='small' style={{margin:'0 0 12px 0'}}>Tempo de cota</Header>
            <Form>
              <Form.Group inline>
                <Form.Radio label='15s' value='15' checked={this.state.quotaType==='15'} onChange={this.onQuotaTypeChanged.bind(this)} />
                <Form.Radio label='30s' value='30' checked={this.state.quotaType==='30'} onChange={this.onQuotaTypeChanged.bind(this)} />
              </Form.Group>
            </Form>

            <Header size='small' style={{margin:'0 0 12px 0'}}>Cotas</Header>
            <Input style={{width:'100%'}} value={this.state.quotas} onChange={this.onQuotasChanged.bind(this)} />

            <Header size='small' style={{margin:'20px 0 12px 0'}}>Descrição</Header>
            <Form>
              <TextArea name='desc' value={this.state.additionalData.desc||''} onChange={this.handleInput.bind(this)} />
            </Form>
          </div>
          <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
            { !isMobile &&
              <div style={{textAlign:'center'}}>
                <Statistic size='tiny' color='blue' value={this.state.quotas * (this.state.quotaType==='30'?30:15)} label={this.labelWithHint('segundos', `de vídeo produzido`, true)} />
                <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/this.state.quotas)} label={this.labelWithHint('custo cota', 'valor médio', true)} />
              </div>
            }
            { this.discountComponent() }
          </div>
        </div>
      );
    }

    return (
      <div className='ProductOptions' style={productOptionsOverride}>
        <div className='left-panel' style={leftPanelOverride}>
          <Header size='small' style={{margin:'0 0 12px 0'}}>Tempo de cota</Header>
          <Form>
            <Form.Group inline>
              <Form.Radio label='15s' value='15' checked={this.state.quotaType==='15'} onChange={this.onQuotaTypeChanged.bind(this)} />
              <Form.Radio label='30s' value='30' checked={this.state.quotaType==='30'} onChange={this.onQuotaTypeChanged.bind(this)} />
            </Form.Group>
          </Form>

          <Header size='small' style={{margin:'0 0 12px 0'}}>Cotas por cinema</Header>
          <Input style={{width:'100%'}} value={this.state.quotas} onChange={this.onQuotasChanged.bind(this)} />

          <Header size='tiny' style={{margin:'26px 0 12px 0'}}>Início de veiculação</Header>
          <Form>
            <Form.Group>
              <div className='field'>
                <div className='ui left icon input'>
                  <i aria-hidden="true" className="calendar icon"></i>
                  <DayPickerInput ref="startDate" onDayChange={this.onStartDateChanged.bind(this)} value={moment(product.startDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: startDateDisabledDays }} />
                </div>
              </div>
            </Form.Group>
          </Form>

          <Header size='tiny' style={{margin:'20px 0 12px 0'}}>
            <Dropdown text='Fim de veiculação'>
              <Dropdown.Menu>
                <Dropdown.Item text='Plano Trimestral' description='13 semanas' onClick={()=>this.planSelect(13)}/>
                <Dropdown.Item text='Plano Semestral' description='26 semanas' onClick={()=>this.planSelect(26)} />
                <Dropdown.Item text='Plano Anual' description='52 semanas' onClick={()=>this.planSelect(52)} />
              </Dropdown.Menu>
            </Dropdown>
          </Header>

          <Form>
            <Form.Group>
              <div className='field'>
                <div className='ui left icon input'>
                  <i aria-hidden="true" className="calendar icon"></i>
                  <DayPickerInput ref="endDate" onDayChange={this.onEndDateChanged.bind(this)} value={moment(product.endDate).toDate()} format='DD/MM/YYYY' formatDate={formatDate} parseDate={parseDate} dayPickerProps={{ localeUtils: LocaleUtils, locale: 'pt-BR', disabledDays: endDateDisabledDays }} />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div style={{width:isMobile?'100%':'65%', padding:'40px 0'}}>
          { !isMobile &&
            <div style={{textAlign:'center'}}>
              <Statistic size='tiny' color='blue' value={theatersCount} label={theatersCount>1?'cinemas':'cinema'} />
              <Statistic size='tiny' color='blue' value={weeks} label={weeks>1?'semanas':'semana'} />
              <Statistic size='tiny' color='blue' value={product.quotaTotalSeconds()} label={this.labelWithHint('segundos', `de veiculação ${theatersCount>1?'por cinema':''}`, true)} />
            </div>
          }

          { !isMobile &&
            <div className='smaller' style={{textAlign:'center',marginTop:8}}>
              <Statistic size='tiny' color='blue' value={formatNumber(peopleCount)} label={this.labelWithHint('público', 'médio estimado')} />
              <Statistic size='tiny' color='blue' value={formatNumber(insertions)} label={this.labelWithHint('inserções', 'número estimado')} />
              <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/(insertions||1))} label={this.labelWithHint('custo inserção', 'valor médio estimado')} />
              <Statistic size='tiny' color='olive' value={formatCurrency(product.total()/(peopleCount||1))} label={this.labelWithHint('custo impressão', 'valor médio estimado')} />
            </div>
          }

          { this.discountComponent() }
        </div>
      </div>
    );
  }
}

const networks = [
  { key: 1, value: 1, text: 'Cineflix' }
];
