import React, { Component } from 'react';
import { Grid, Image, Transition, Icon, Checkbox } from 'semantic-ui-react';

import IDs from 'unid-core/lib/utils/IDs';

import moment from 'moment';

import './MovieViwer.css';
export default class MovieViwer extends Component {
  state = { movie: null, animate: false };

  show(movie) {
    if (!this.state.animate) setTimeout(() => this.setState({ animate: true }), 1);
    this.setState({ movie: movie, animate: this.state.animate });
  }

  hide(animate) {
    if (animate) this.setState({ animate: false });
    else this.setState({ movie: null, animate: false });
  }
  
  render() {
    let movie = this.state.movie ? { ...this.state.movie } : null;
    if (movie) movie.additional_data = JSON.parse(movie.additional_data);

    return (
      <Transition visible={this.state.animate} animation='fade down' duration={250} onHide={() => this.setState({ movie: null })}>
        { movie ?
          <Grid.Column mobile={16} className='MovieViwer'>
            <div className='backdrop'>
              { !this.state.initial && movie.additional_data.backdrop &&
                <Image floated='right' height='100%' src={`https://image.tmdb.org/t/p/w500${movie.additional_data.backdrop}`} />
              }
            </div>
            <div className='title'>{movie.title}</div>
            { movie.additional_data.tagline && <div className='subtitle'>{movie.additional_data.tagline}</div> }
            <div className='info'>
              <ul>
                <li><b className='green'>Estréia:</b> {moment.utc(movie.release_date).format('DD/MM/YYYY')}</li>
                { movie.additional_data.genres.length > 0 && <li><b>Gênero:</b> {movie.additional_data.genres.map((e,i) => { return <span key={i} style={{margin:'0 2px'}}>{e}{i<movie.additional_data.genres.length-1?',':''}</span> })}</li> }
                { movie.additional_data.runtime > 0 && <li><b>Duração:</b> {movie.additional_data.runtime} min</li> }
                { (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid) && <li><b>Blockbuster:</b> <Checkbox checked={movie.blockbuster===1} style={{minHeight:0,position:'relative',top:-12,left:4}} onChange={()=>this.props.toggleBlockbuster(movie)}/></li> }
              </ul>
            </div>
            <div className='synopsis'>{movie.additional_data.overview}</div>
            <div className='trailers'>
              <ul>
                { movie.additional_data.trailers.map((e, i) => {
                  return <li key={i}><a href={`https://youtu.be/${e}`} target='_blank' rel='noopener noreferrer'><Icon name='video play' /> Trailer {i+1}</a></li>
                })}
              </ul>
            </div>
          </Grid.Column>
        : <span></span> }
      </Transition>
    );
  }
}