import React, { Component } from 'react';

import { Modal, Form, Button, Header, Dropdown } from 'semantic-ui-react';

import IDs from 'unid-core/lib/utils/IDs';

const roles = [
  { key: IDs.Admin, text: 'Administrador', value: IDs.Admin },
  { key: IDs.Unid, text: 'Equipe UNID', value: IDs.Unid },
  <Dropdown.Divider key='d1' />,
  { key: IDs.Exhibitor, text: 'Exibidor', value: IDs.Exhibitor },
  { key: IDs.Manager, text: 'Gerente', value: IDs.Manager },
  { key: IDs.Marketing, text: 'Marketing', value: IDs.Marketing },
  <Dropdown.Divider key='d2' />,
  { key: IDs.Agency, text: 'Agência', value: IDs.Agency },
  { key: IDs.Advertiser, text: 'Anunciante', value: IDs.Advertiser }
]

export default class UserEditor extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.cleanState();
  }

  cleanState(entity) {
    let state = {
      loading: false,
      mainModalOpen: false,
      editingUser: null,
      values: {
        role: IDs.Admin,
        name: '',
        email: '',
        password: '',
        refs: []
      }
    };

    return state;
  }

  entities() {
    let ret = [];

    if (this.state.values.role === IDs.Agency) {
      for (let entity of this.props.agencies) {
        if (!entity.name && !entity.identity) continue;
        ret.push({
          key: entity.id,
          text: entity.name,
          value: entity.id,
          content: <Header size='tiny' content={entity.name} subheader={entity.identity} />
        });
      }
    }
    
    if (this.state.values.role === IDs.Advertiser) {
      for (let entity of this.props.advertisers) {
        if (!entity.name && entity.identity) continue;
        ret.push({
          key: entity.id,
          text: entity.name,
          value: entity.id,
          content: <Header size='tiny' content={entity.name} subheader={entity.identity} />
        });
      }
    }

    if (this.state.values.role === IDs.Marketing) {
      for (let entity of this.props.networks) {
        if (!entity.name && !entity.identity) continue;
        ret.push({
          key: entity.id,
          text: entity.name,
          value: entity.id,
          content: <Header size='tiny' content={entity.name} subheader={entity.identity} />
        });
      }
    }

    if (this.state.values.role === IDs.Exhibitor) {
      for (let entity of this.props.networks) {
        if (!entity.name && !entity.identity) continue;
        ret.push({
          key: entity.id,
          text: entity.name,
          value: entity.id,
          content: <Header size='tiny' content={entity.name} subheader={entity.identity} />
        });
      }
    }

    if (this.state.values.role === IDs.Manager) {
      for (let entity of this.props.theaters) {
        if (!entity.name || !entity.identity) continue;
        ret.push({
          key: entity.id,
          text: entity.name,
          value: entity.id,
          content: <Header size='tiny' content={entity.name} subheader={entity.identity} />
        });
      }
    }

    return ret;
  }

  handleInputChange(e, { name, value }) {
    if (name === 'role') this.setState({ values: { ...this.state.values, [name]: value, refs: [] }});
    else this.setState({ values: { ...this.state.values, [name]: value }});
  }

  editUser(user) {
    const userId = user ? user['id'] : null;
    const values = user ? { ...user } : this.cleanState().values;
    
    if (user) {
      delete values['id'];
      values['password'] = '';
    }

    this.setState({ ...this.cleanState(user), mainModalOpen: true, editingUser: userId, values: values });
  }

  async save() {
    this.setState({ loading: true });

    if (this.state.editingUser) {
      const res = await  window.cs_post(`/users/${this.state.editingUser}`, this.state.values)
      if (res.status === 200) {
        this.setState({ mainModalOpen: false });
        this.props.onCloseModal();
      } else this.setState({ loading: false });
    } else {
      const res = await  window.cs_post('/users', this.state.values)
      if (res.status === 200) {
        this.setState({ mainModalOpen: false });
        this.props.onCloseModal();
      } else this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div>
        <Modal className='UserEditor' open={this.state.mainModalOpen} onClose={()=>this.setState({ mainModalOpen: false })}>
          <Modal.Header>{this.state.editingUser?'Editando usuário':'Novo usuário'}</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading}>
              <Form.Group widths='equal'>
                <Form.Select width={6} style={{margin:0}} name='role' fluid value={this.state.values.role} options={roles} label='Grupo' placeholder='Selecione um grupo' onChange={this.handleInputChange.bind(this)} />
                <Form.Select disabled={this.state.values.role===IDs.Admin||this.state.values.role===IDs.Unid} width={10} style={{margin:0}} name='refs' fluid multiple value={this.state.values.refs} options={this.entities()} search label='Referências' placeholder='Selecione um cadastro' noResultsMessage='Nada encontrado' onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input width={6} name='name' label='Nome' value={this.state.values.name} icon='user' iconPosition='left' onChange={this.handleInputChange.bind(this)} />
                <Form.Input width={5} name='email' label='E-mail' value={this.state.values.email} icon='mail' iconPosition='left' onChange={this.handleInputChange.bind(this)} />
                <Form.Input width={5} name='password' label='Senha' value={this.state.values.password} icon='asterisk' iconPosition='left' type='password' placeholder={this.state.editingUser?'Alterar senha':null} onChange={this.handleInputChange.bind(this)} />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancelar' onClick={()=>this.setState({ mainModalOpen: false })} />
            <Button color='olive' labelPosition='right' icon='check' content={this.state.editingUser?'Salvar':'Adicionar'} onClick={this.save.bind(this)} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}