import React, { Component } from 'react';
import { Input, Message, Item, Checkbox, Responsive } from 'semantic-ui-react';

import { getTheatersMarkers, getNetworkByTheaterId } from 'unid-core/lib/Theaters';
import { formatNumber } from 'unid-core/lib/utils/formatter';
import { removeAccents } from 'unid-core/lib/utils/string';

import IDs from 'unid-core/lib/utils/IDs';

export default class TheatersMap extends Component {
  constructor(props, context) {
    super(props, context);

    const { role, refs } = this.props.userData;

    this.state = {
      isMobile: window.innerWidth < Responsive.onlyComputer.minWidth,
      showWarning: true,
      searchValue: '',
      theatersMarkers: role === IDs.Manager ?
        getTheatersMarkers(this.props.theaters, this.props.product, getNetworkByTheaterId(refs[0].id).id) :
        getTheatersMarkers(this.props.theaters, this.props.product)
    };

    this.selectedCity = null;
  }

  processPoints(geometry, cb, thisArg) {
    if (geometry instanceof window.google.maps.LatLng) {
      cb.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
      cb.call(thisArg, geometry.get());
    } else {
      geometry.getArray().forEach((g) => {
        this.processPoints(g, cb, thisArg);
      });
    }
  }

  search(value) {
    let results = [];
    const _value = removeAccents(value).toLowerCase();

    for (let city of this.state.theatersMarkers) {
      const cityName = removeAccents(city.theaters[0].city).toLowerCase();
      const stateName = removeAccents(city.theaters[0].state).toLowerCase();

      if (cityName.indexOf(_value) >= 0 || stateName.indexOf(_value) >= 0)
        results = results.concat(city.theaters);
      else {
        const theaters = city.theaters;

        for (let theater of theaters) {
          const networkName = removeAccents(theater.network?theater.network.name:theater.name).toLowerCase();
          const placeName = removeAccents(theater.name).toLowerCase();

          if (networkName.indexOf(_value) >= 0 || placeName.indexOf(_value) >= 0)
            results.push(theater);
        }
      }
    }

    results.sort((x, y) => x.stats.public > y.stats.public ? -1 : 1);
    return results;
  }

  createMarker(city) {
    let hadMap = false;

    if (city.marker) {
      hadMap = city.marker.map;
      city.marker.setMap(null);
    }

    const icon = {
      path: "M512 0c-211.555 0-383.288 167.111-383.288 372.978s383.288 651.022 383.288 651.022 383.288-445.155 383.288-651.022-171.733-372.978-383.288-372.978zM512 537.6c-93.511 0-169.245-73.955-169.245-164.622 0-91.022 75.733-164.622 169.245-164.622s169.245 73.6 169.245 164.622c0 91.022-75.733 164.622-169.245 164.622z",
      fillColor: this.cityHasTheaterSelected(city) ? '#F00' : '#000',
      fillOpacity: this.selectedCity === city ? 0.95 : .6,
      anchor: new window.google.maps.Point(512,1024),
      strokeWeight: 0,
      scale: 0.03
    };

    city.marker = new window.google.maps.Marker({
      position: { lat: city.lat, lng: city.lng },
      map: hadMap ? this.map : null,
      title: city.name,
      icon: icon
    });

    city.marker.addListener('click', () => {
      const oldCity = this.selectedCity;
      this.selectedCity = city;

      this.setState({ showWarning: false, searchValue: '' }, () => {
        this.createMarker(city);
        if (oldCity) this.createMarker(oldCity);
      });
    });
  }

  createMarkers() {
    for (let city of this.state.theatersMarkers) {
      this.createMarker(city);
    }
  }

  showMarkers() {
    for (let city of this.state.theatersMarkers) {
      city.marker.setMap(this.map);
    }
  }

  hideMarkers() {
    if (this.state.isMobile) return;
    for (let city of this.state.theatersMarkers) {
      city.marker.setMap(null);
    }
  }

  searchValueOnChange(e, data) {
    const oldCity = this.selectedCity;
    this.selectedCity = null;

    this.setState({ showWarning: false, searchValue: data.value, searchResults: this.search(data.value) });
    if (oldCity) this.createMarker(oldCity);
  }

  cityHasTheaterSelected(city) {
    for (let theater of city.theaters)
      if (theater.selected) return true;
    return false;
  }

  selectTheater(theater, state) {
    theater.selected = state;
    this.createMarker(theater.cityRef);
    this.forceUpdate();
  }

  componentDidMount() {
    if (this.state.isMobile) return;

    this.map = new window.google.maps.Map(document.getElementById('map'), {
      disableDefaultUI: true,
      zoomControl: true,
      zoom: 4,
      center: new window.google.maps.LatLng(-15.5306147, -53.2410579),
      styles: [  { "featureType": "administrative", "elementType": "all", "stylers": [ { "visibility": "on" }, { "saturation": -100 }, { "lightness": 20 } ] }, { "featureType": "road", "elementType": "all", "stylers": [ { "visibility": "on" }, { "saturation": -100 }, { "lightness": 40 } ] }, { "featureType": "water", "elementType": "all", "stylers": [ { "visibility": "on" }, { "saturation": -10 }, { "lightness": 30 } ] }, { "featureType": "landscape.man_made", "elementType": "all", "stylers": [ { "visibility": "simplified" }, { "saturation": -60 }, { "lightness": 10 } ] }, { "featureType": "landscape.natural", "elementType": "all", "stylers": [ { "visibility": "simplified" }, { "saturation": -60 }, { "lightness": 60 } ] }, { "featureType": "poi", "elementType": "all", "stylers": [ { "visibility": "off" }, { "saturation": -100 }, { "lightness": 60 } ] }, { "featureType": "transit", "elementType": "all", "stylers": [ { "visibility": "off" }, { "saturation": -100 }, { "lightness": 60 } ] } ]
    });

    this.createMarkers();

    this.map.addListener('zoom_changed', () => {
      if (this.map.getZoom() <= 4) {
        data.setMap(this.map);
        this.hideMarkers();
      }
      else {
        data.setMap(null);
        this.showMarkers();
      }
    });

    let data = new window.google.maps.Data();
    data.loadGeoJson('/geojson/brazil.geojson', {}, () => {
      data.setMap(this.map);
    });

    data.setStyle({
      fillColor:'#999',
      strokeOpacity: 0
    });

    data.addListener('mouseover', (e) => {
      data.revertStyle();
      data.overrideStyle(e.feature, { fillColor: 'blue' });
    });

    data.addListener('mouseout', (e) => {
      data.revertStyle();
    });

    data.addListener('click', (e) => {
      let bounds = new window.google.maps.LatLngBounds();
      this.processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      this.map.fitBounds(bounds);

      data.setMap(null);
    });
  }

  componentWillUnmount() {
    this.hideMarkers();
  }

  theatherComponent(key, theater) {
    return (
      <Item key={key}>
        <Item.Content>
          <Item.Header style={{fontSize:15}}><Checkbox toggle checked={theater.selected} style={{position:'relative',top:5,marginRight:8}} onChange={(e, data)=>this.selectTheater(theater, data.checked)}/> {theater.network?theater.network.name:theater.name}</Item.Header>
          <Item.Description style={{fontSize:13}}>{theater.additional_data.shopping}</Item.Description>
          <Item.Description style={{fontSize:11,marginTop:0}}>{theater.city} - {theater.uf}</Item.Description>
          <Item.Extra>
            <span className='icone-midia-em-tela' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> {theater.stats.rooms}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className='icone-poltrona' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> {formatNumber(theater.stats.seats)}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className='icone-grupo' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> {formatNumber(theater.stats.public)}
            {/*&nbsp;&nbsp;&nbsp;&nbsp;
            <span className='icone-perfil' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> {theater.stats.class}*/}
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }

  render() {
    const { isMobile } = this.state;

    return (
      <div style={{display:'flex'}}>
        { !isMobile && <div id="map" style={{width:'70%',height:500,margin:0}} /> }
        <div style={{width:isMobile?'100%':'30%',padding:20,maxHeight:500,overflowY:'auto'}}>
          { this.state.showWarning &&
            <Message size='tiny' warning>
              { isMobile && 'Nenhuma cidade selecionada. Pesquise abaixo para adicionar praças.' }
              { !isMobile && 'Nenhuma cidade selecionada. Navegue pelo mapa ou pesquise abaixo.' }
            </Message>
          }

          <Input fluid value={this.state.searchValue} size='tiny' icon='search' placeholder='Pesquisar...' onChange={this.searchValueOnChange.bind(this)} />

          <Item.Group divided>
            { (this.selectedCity || (!this.selectedCity && this.state.searchResults)) &&
              (this.selectedCity ? this.selectedCity.theaters : this.state.searchResults).map((theater, i) => this.theatherComponent(i, theater) )
            }
          </Item.Group>
        </div>
      </div>
    );
  }
}
