import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { parseProductsFromOrder, sortTheatersByState } from 'unid-core/lib/utils/products';
import IDs from 'unid-core/lib/utils/IDs';

function combosText(product) {
  let ret = '';

  if (product.additionalData.little_combo > 0)
    ret += `${ret?'<br/>':''}${product.additionalData.little_combo} pequenos (${formatCurrency(product.additionalData.little_combo*9.50)})`;
  if (product.additionalData.medium_combo > 0)
    ret += `${ret?'<br/>':''}${product.additionalData.medium_combo} médios (${formatCurrency(product.additionalData.medium_combo*12.50)})`;
  if (product.additionalData.big_combo > 0)
    ret += `${ret?'<br/>':''}${product.additionalData.big_combo} grandes (${formatCurrency(product.additionalData.big_combo*14)})`;
  
  return ret || 'Nenhum';
}

export async function createProposalTable(proposal) {
  try {
    const order = (await window.cs_get(`/orders/${proposal.id}`, { full: true })).data;
    const products = parseProductsFromOrder(order);

    let TABLE = '<html><head><title>Tabela da proposta '+order.id+'</title><style>';
      TABLE += 'table, th, td, h3, p { font-family: Calibri; color: #004166; }';
      TABLE += 'table, th, td { border: 1px solid #004166; border-collapse: collapse; }';
      TABLE += 'th, td { padding: 2px 5px; }';
      TABLE += 'th span { font-size: 11px; font-weight: 400; }';
      TABLE += 'td span { font-size: 11px; }';
      TABLE += 'th { font-size: 16px; background-color: #a5d5e5; }';
      TABLE += 'td { font-size: 15px; text-align: center; }';
      TABLE += 'h3 { margin: 20px 0 4px; }';
      TABLE += 'p { font-size: 13px; margin: 0 0 6px; white-space: pre-line; }';
    TABLE += '</style></head><body>';

    for (let product of products) {
      let quotasStr = `${product.quotas} ${product.quotas < 2 ? 'cota' : 'cotas'}`;
      const weeksStr =  `${product.weeks()} ${product.weeks() < 2 ? 'semana' : 'semanas'}`;
      const daysStr = `${product.days()} ${product.days() < 2 ? 'dia' : 'dias'}`;

      if (product.id === IDs.Preshow || product.id === IDs.Videowall || product.id === IDs.Bomboniere || product.id === IDs.Foyer || product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics)
        quotasStr += ` de ${product.quotaType}s`;

      if (product.id === IDs.NamingRights)
        TABLE += `<h3>${product.name.toUpperCase()} - ${product.quotas} pessoas - ${product.startDate.format('DD/MM/YYYY')} a ${product.endDate.format('DD/MM/YYYY')} (${weeksStr})</h3>`;
      else if (product.id === IDs.PromoAction)
        TABLE += `<h3>${product.name.toUpperCase()} - ${product.startDate.format('DD/MM/YYYY')} a ${product.endDate.format('DD/MM/YYYY')} (${weeksStr})</h3>`;
      else if (product.id === IDs.Event) {
        let period = '';
        if (product.startDate.format('DD/MM/YYYY') === product.endDate.format('DD/MM/YYYY')) period = `em ${product.startDate.format('DD/MM/YYYY')}`;
        else period = `de ${product.startDate.format('DD/MM/YYYY')} a ${product.endDate.format('DD/MM/YYYY')} (${daysStr})`;
        TABLE += `<h3>${product.name.toUpperCase()} - ${product.quotas} pessoas ${period}</h3>`;
      }
      else if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics)
        TABLE += `<h3>${product.name.toUpperCase()} - ${quotasStr}</h3>`;
      else if (product.id === IDs.CorporateTicket)
        TABLE += `<h3>${product.name.toUpperCase()} - ${product.quotas} ${product.quotas < 2 ? 'ingresso' : 'ingressos'}</h3>`;
      else
        TABLE += `<h3>${product.name.toUpperCase()} - ${quotasStr} - ${product.startDate.format('DD/MM/YYYY')} a ${product.endDate.format('DD/MM/YYYY')} (${weeksStr})</h3>`;

      if (product.additionalData && product.additionalData.desc) TABLE += `<p>${product.additionalData.desc}</p>`;

      if (product.id === IDs.NamingRights || product.id === IDs.PromoAction) {
        TABLE += '<table width="100%" border="1"><thead><tr><th colspan="2">Cinema</th><th colspan="3">Informações</th></tr>';
        TABLE += `<tr><th>Praça</th><th>Complexo</th><th>Preço de Tabela</th><th>Desconto</th><th>Valor Negociado</th></tr>`;
      }
      else if (product.id === IDs.Event) {
        TABLE += '<table width="100%" border="1"><thead><tr><th colspan="2">Cinema</th><th colspan="4">Informações</th></tr>';
        TABLE += `<tr><th>Praça</th><th>Complexo</th><th>Combos</th><th>Preço de Tabela</th><th>Desconto</th><th>Valor Negociado</th></tr>`;
      }
      else if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics || product.id === IDs.CorporateTicket) {
        TABLE += '<table width="100%" border="1"><thead><tr><th colspan="3">Informações</th></tr>';
        TABLE += `<tr><th>Preço de Tabela</th><th>Desconto</th><th>Valor Negociado</th></tr>`;
      }
      else {
        TABLE += '<table width="100%" border="1"><thead><tr><th colspan="2">Cinema</th><th colspan="7">Informações</th></tr>';
        TABLE += `<tr><th>Praça</th><th>Complexo</th><th>Público atingido<br/><span>número médio estimado</span></th><th>Custo de Impressão<br/><span>valor médio por pessoa atingida</span></th><th>Inserções<br/><span>número médio estimado</span></th><th>Custo de Inserção<br/><span>valor médio por inserção</span></th><th>Preço de Tabela</th><th>Desconto</th><th>Valor Negociado</th></tr>`;
      }

      const states = sortTheatersByState(product);
      const insertions = product.totalInsertionsPerTheater();

      const total_per_theater = product.total() / product.theaters.length;
      const total_per_theater_wo_discount = (product.totalWithoutDiscount() / product.theaters.length) - (product.id === IDs.Event ? product.total_combos() : 0);
      const discount_per_theater = product.discount / product.theaters.length;

      let total_combos = 0;

      let total_public = 0;

      //let firstTheater = true;

      TABLE += '</thead><tbody>';

      for (let state of states) {
        for (let city of state.cities) {
          if (!city.selectedCount) continue;

          //let cityInserted = false;
          //let lastNetwork = '';

          for (let theater of city.theaters) {
            if (!theater.selected) continue;

            const theater_public = theater.additional_data.public * product.months(true);
            total_public += theater_public;

            TABLE += '<tr>';

            // if (!cityInserted) desabilitado agrupamento de cidades: TABLE += `<td rowspan="${city.selectedCount}">${city.theaters[0].city} (${city.theaters[0].uf})</td>`;
              TABLE += `<td>${city.theaters[0].city} (${city.theaters[0].uf})</td>`;
            /*if (lastNetwork !== theater.network.name)
              TABLE += `<td rowspan="${theater.networkSelectedCount}">${theater.network.name}</td>`;*/
            
            TABLE += `<td>${theater.additional_data.shopping}</td>`;

            if (product.id !== IDs.NamingRights && product.id !== IDs.PromoAction && product.id !== IDs.Event) {
              TABLE += `<td>${formatNumber(theater_public)}</td>`;
              TABLE += `<td>${formatCurrency(total_per_theater / (theater_public||1))}</td>`;
            }

            /*if (firstTheater) { desabilitado 1 linha por cidade, rowspan="${product.theaters.length}" em todas as linhas td */
              if (product.id !== IDs.NamingRights && product.id !== IDs.PromoAction && product.id !== IDs.Event) {
                TABLE += `<td>${formatNumber(insertions)}</td>`;
                TABLE += `<td>${formatCurrency(total_per_theater / (insertions||1))}</td>`;
              }

              if (product.id === IDs.Event) {
                TABLE += `<td>${combosText(product)}</td>`;
                total_combos += product.total_combos();
              }
              
              if (discount_per_theater !== 0) {
                TABLE += `<td style="color: #d71d24;text-decoration:line-through;font-weight:bold;">${formatCurrency(total_per_theater_wo_discount)}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(total_per_theater_wo_discount/order.installments)}</span>`:''}</td>`;
                TABLE += `<td>${formatCurrency(discount_per_theater)}<br/><span>${formatNumber((discount_per_theater/(total_per_theater_wo_discount+(product.id === IDs.Event ? product.total_combos() : 0)))*100,1)}&#37</span></td>`;
              } else {
                TABLE += `<td style="font-weight:bold;">${formatCurrency(total_per_theater_wo_discount)}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(total_per_theater_wo_discount/order.installments)}</span>`:''}</td>`;
                TABLE += `<td>-</td>`;
              }
              
              TABLE += `<td style="color: #1f8900;font-weight:bold;">${formatCurrency(total_per_theater)}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(total_per_theater/order.installments)}</span>`:''}</td>`;
            //}

            TABLE += '</tr>';

            //cityInserted = true;
            //firstTheater = false;
            //lastNetwork = theater.network.name;
          }
        }
      }

      if (product.id === IDs.VideoEditing || product.id === IDs.VideoMotionGraphics || product.id === IDs.CorporateTicket) {
        TABLE += '<tr>';

        if (product.discount !== 0) {
          TABLE += `<td style="color: #d71d24;text-decoration:line-through;font-weight:bold;">${formatCurrency(product.totalWithoutDiscount())}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(product.totalWithoutDiscount()/order.installments)}</span>`:''}</td>`;
          TABLE += `<td>${formatCurrency(product.discount)}<br/><span>${formatNumber(product.discount/product.totalWithoutDiscount()*100,1)}&#37</span></td>`;
        } else {
          TABLE += `<td style="font-weight:bold;">${formatCurrency(product.totalWithoutDiscount())}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(product.totalWithoutDiscount()/order.installments)}</span>`:''}</td>`;
          TABLE += `<td>-</td>`;
        }

        TABLE += `<td style="color: #1f8900;font-weight:bold;">${formatCurrency(product.total())}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(product.total()/order.installments)}</span>`:''}</td>`;
        TABLE += '</tr>';
      }

      if (product.theaters.length > 1) {
        TABLE += '<tr><th colspan="2" style="text-align:right">Total</th>';

        if (product.id !== IDs.NamingRights && product.id !== IDs.PromoAction && product.id !== IDs.Event) {
          TABLE += `<th>${formatNumber(total_public)}</th>`;
          TABLE += `<th>${formatCurrency(product.total() / (total_public||1))}</th>`;

          TABLE += `<th>${formatNumber(product.totalInsertions())}</td>`;
          TABLE += `<th>${formatCurrency(product.total() / (product.totalInsertions()||1))}</td>`;
        }

        if (product.id === IDs.Event) {
          TABLE += `<th>${formatCurrency(total_combos)}</th>`;
        }
        
        if (product.discount !== 0) {
          TABLE += `<th style="color: #d71d24;text-decoration:line-through;font-weight:bold;">${formatCurrency(product.totalWithoutDiscount() - total_combos)}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency((product.totalWithoutDiscount()-total_combos)/order.installments)}</span>`:''}</th>`;
          TABLE += `<th>${formatCurrency(product.discount)}<br/><span>${formatNumber(product.discount/product.totalWithoutDiscount()*100,1)}&#37</span></th>`; 
        }
        else {
          TABLE += `<th style="font-weight:bold;">${formatCurrency(product.totalWithoutDiscount() - total_combos)}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(product.totalWithoutDiscount()/order.installments)}</span>`:''}</th>`;
          TABLE += `<th>-</th>`;
        }
        
        TABLE += `<th style="color: #1f8900;font-weight:bold;">${formatCurrency(product.total())}${order.installments>1?`<br/><span>${order.installments}x de ${formatCurrency(product.total()/order.installments)}</span>`:''}</th></tr>`;
      }

      TABLE += '</tbody></table>';
    }      

    TABLE += '</body>';

    try {
      let w = window.open('','_blank','status=0,toolbar=0,menubar=0,left=50,top=50,width='+((window.innerWidth||900)-100)+',height=500');
      w.document.write(TABLE);
      w.document.close();
    } catch (err) {
      alert('Ocorreu um erro ao gerar a tabela. Desbloqueie popups em seu navegador para continuar.');
    }
  } catch (err) {
    console.error(err);
  }
}