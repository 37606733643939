import React, { Component } from 'react';
import { Grid, Form, Table, Button, Dimmer, Loader, Message, Popup, Select } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { ResponsiveContainer, ComposedChart, PieChart, Pie, Area, Cell, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

import ContentHeader from '../../components/ContentHeader';

import moment from 'moment';

import { parseProductsFromOrder } from 'unid-core/lib/utils/products';
import { formatCurrency, formatNumber } from 'unid-core/lib/utils/formatter';
import { getNetworkById, getTheaterById } from 'unid-core/lib/Theaters';
import { capitalizeFirstLetter } from 'unid-core/lib/utils/string';
import IDs from 'unid-core/lib/utils/IDs';

import { createProposalTable } from '../utils/proposal';
import { rgbToHsl, hslToRgb } from '../utils/colors';
import { CS_API_PATH } from '../../links';

const types = [
  { key: 0, value: 0, text: 'Pedidos criados' },
  { key: 1, value: 1, text: 'Propostas criadas' },
  { key: 2, value: 2, text: 'Pagamentos', disabled: true }
];

export default class Charts extends Component {
  state = {
    loading: false,
    data: [],
    graphType: 0,
    paymentsMonth: moment().format('YYYY-MM'),
    startDate: moment().subtract(1, 'month').toDate(),
    endDate: moment().toDate(),
    showSum: true
  };

  constructor(props, context) {
    super(props, context);
    this.onMonthChanged = this.onMonthChanged.bind(this);
  }

  months() {
    let ret = [];
    let date = moment('2018-01-01');

    while(date.isBefore(moment())) {
      const dateStr = date.format('YYYY-MM');
      ret.push({ key: dateStr, value: dateStr, text: capitalizeFirstLetter(date.format('MMMM [de] YYYY')) });
      date.add(1, 'month');
    }

    return ret;
  }

  onTypeChanged(e, { value }) {
    const needsUpdate = this.state.graphType !== value;

    this.setState({ graphType: value }, () => {
      if (needsUpdate) this.getChartData();
    });
  }

  onMonthChanged(e, { value }) {
    this.setState({ paymentsMonth: value }, () => this.getChartData());
  }

  async getChartData() {
    this.setState({ loading: true });

    try {
      let res = await window.cs_get('/orders', { type: this.state.graphType === 0 || this.state.graphType === 2 ? 1 : 2, startDate: moment(this.state.paymentsMonth, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), endDate: moment(this.state.paymentsMonth, 'YYYY-MM').endOf('month').format('YYYY-MM-DD') });

      let ids = [];
      for (let order of res.data) if (order.active) ids.push(order.id);

      res = await window.cs_get('/orders/full', { ids: ids.toString() });
      this.setState({ data: res.data, loading: false });
    } catch (err) {
      console.error(err);
      alert('Ocorreu um erro inesperado ao gerar os gráficos.');
    }
  }

  async createProposal(e) {
    await createProposalTable(e);
  }

  componentDidMount() {
    this.getChartData();
  }

  render() {
    let barData = [];
    let productsData = [];
    let theatersData = [];
    let networksData = [];

    function findKey(obj, key, value) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i][key] === value) return i;
      }
      return -1;
    }

    let sum = 0;
    for (let j = this.state.data.length-1; j >= 0; j--) {
      const order = this.state.data[j];
      const products = parseProductsFromOrder(order);
      if (this.state.showSum) sum += order.total;

      // Padrão
      let idx = findKey(barData, 'date', moment(order.date).format('DD/MM/YY'));
      if (idx === -1) {
        barData.push({ total: order.total, sum, date: moment(order.date).format('DD/MM/YY') });
      } else {
        barData[idx].total += order.total;
        barData[idx].sum = sum;
      }

      // Produtos
      for (let product of products) {
        idx = findKey(productsData, 'id', product.id);
        if (idx === -1) productsData.push({ id: product.id, name: product.name, value: product.total() });
        else productsData[idx].value += product.total();

        // Cinemas
        for (let theater of product.theaters) {

          idx = findKey(theatersData, 'id', theater.id);
          if (idx === -1) theatersData.push({ id: theater.id, name: theater.name, value: product.total() });
          else theatersData[idx].value += product.total();

          if (!theater.network) continue;

          // Rede
          idx = findKey(networksData, 'id', theater.network.id);
          if (idx === -1) networksData.push({ id: theater.network.id, name: theater.network.name, value: product.total() });
          else networksData[idx].value += product.total();
        }
      }
    }

    productsData.sort((x, y) => {
      if (x.value > y.value) return 1;
      if (x.value < y.value) return -1;
      else return 0;
    });

    theatersData.sort((x, y) => {
      if (x.value > y.value) return 1;
      if (x.value < y.value) return -1;
      else return 0;
    });

    networksData.sort((x, y) => {
      if (x.value > y.value) return 1;
      if (x.value < y.value) return -1;
      else return 0;
    });

    const baseColor = rgbToHsl(0, 131, 179);
    const productsStep = 1 / (productsData.length||1);
    const theatersStep = 1 / (theatersData.length||1);
    const networksStep = 1 / (networksData.length||1);
    let productsHue = baseColor[0];
    let theatersHue = baseColor[0];
    let networksHue = baseColor[0];

    return (
      <Grid padded className='Reports'>
        <ContentHeader userData={this.props.userData} path={['Relatórios']}/>

        <Form style={{backgroundColor:'#FCFCFC',width:'100%',padding:'12px 14px'}}>
          <Form.Group style={{margin:0}}>
            <Select value={this.state.graphType} options={types} onChange={this.onTypeChanged.bind(this)} disabled={this.state.loading} />
            <Select style={{marginLeft:'16px'}} value={this.state.paymentsMonth} options={this.months()} onChange={this.onMonthChanged} />
            {this.state.graphType === 0 && <Button style={{marginLeft:'16px'}} onClick={() => window.location = `${CS_API_PATH}/reports/orders` } icon='download' content='Relatório completo' /> }
            {this.state.graphType === 0 && <Button style={{marginLeft:'16px'}} onClick={() => window.location = `${CS_API_PATH}/reports/orders-per-theater` } icon='download' content='Relatório por praça' /> }
          </Form.Group>
        </Form>

        <Grid.Row className='content-margin'>
          <Grid.Column>
            <Dimmer active={this.state.loading} inverted>
              <Loader inline size='big' content='Carregando dados...' />
            </Dimmer>

            { !this.state.loading && this.state.data.length === 0 &&
              <Message warning content='Nada encontrado com base nos filtros informados.' />
            }

            { this.state.data.length > 0 &&
              <div>
                <ResponsiveContainer width='100%' height={350}>
                  <ComposedChart margin={{left:6}} data={barData}>
                    <XAxis dataKey='date' />
                    <YAxis tickFormatter={(v)=>formatNumber(v, 0)} />
                    <Tooltip formatter={(v)=>formatCurrency(v, 2)} />
                    <Legend onClick={(e) => { if (e.dataKey === 'sum') this.setState({ showSum: !this.state.showSum }); }}/>
                    <CartesianGrid stroke='#f5f5f5' />
                    <Area name='Acumulado' dataKey='sum' type='monotone' fill='#a9b9bf' stroke='#a9b9bf' />
                    <Bar name='Valor' dataKey='total' fill='#006A92' maxBarSize={50} />
                  </ComposedChart>
                </ResponsiveContainer>

                <div style={{margin:'48px 0 0 0',textAlign:'center'}}>
                  <p style={{width:this.props.userData.role!==IDs.Exhibitor?'33%':'50%',display:'inline-block',margin:0,fontWeight:600,color:'#006a92'}}>Participação por produto</p>
                  <p style={{width:this.props.userData.role!==IDs.Exhibitor?'33%':'50%',display:'inline-block',margin:0,fontWeight:600,color:'#006a92'}}>Participação por cinema</p>
                  { this.props.userData.role !== IDs.Exhibitor && <p style={{width:'33%',display:'inline-block',margin:0,fontWeight:600,color:'#006a92'}}>Participação por rede</p> }
                </div>

                <ResponsiveContainer width='100%' height={this.props.userData.role!==IDs.Exhibitor?250:300}>
                  <PieChart>
                    <Tooltip formatter={(v)=>formatCurrency(v, 2)} />
                    <Pie data={productsData} dataKey='value' cx={this.props.userData.role!==IDs.Exhibitor?'18%':'25%'}>
                      {
                        productsData.map((entry, index) => {
                          productsHue += productsStep;
                          if (productsHue > 1) productsHue -= 1;
                          const rgb = hslToRgb(productsHue, baseColor[1], baseColor[2]);
                          return <Cell key={`cell-${index}`} fill={`rgb(${Math.round(rgb[0])}, ${Math.round(rgb[1])}, ${Math.round(rgb[2])})`} />
                        })
                      }
                    </Pie>
                    <Pie data={theatersData} dataKey='value' cx={this.props.userData.role!==IDs.Exhibitor?'50%':'75%'}>
                      {
                        theatersData.map((entry, index) => {
                          theatersHue += theatersStep;
                          if (theatersHue > 1) theatersHue -= 1;
                          const rgb = hslToRgb(theatersHue, baseColor[1], baseColor[2]);
                          return <Cell key={`cell-${index}`} fill={`rgb(${Math.round(rgb[0])}, ${Math.round(rgb[1])}, ${Math.round(rgb[2])})`} />
                        })
                      }
                    </Pie>
                    { this.props.userData.role !== IDs.Exhibitor &&
                      <Pie data={networksData} dataKey='value' cx='82%'>
                        {
                          networksData.map((entry, index) => {
                            networksHue += networksStep;
                            if (networksHue > 1) networksHue -= 1;
                            const rgb = hslToRgb(networksHue, baseColor[1], baseColor[2]);
                            return <Cell key={`cell-${index}`} fill={`rgb(${Math.round(rgb[0])}, ${Math.round(rgb[1])}, ${Math.round(rgb[2])})`} />
                          })
                        }
                      </Pie>
                    }
                  </PieChart>
                </ResponsiveContainer>

                { !this.state.loading &&
                  <Table basic style={{fontSize:12,marginTop:16}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign='center'>ID</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Data</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Valor</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Repasse Cinema</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      { this.state.data.map((e) => {
                        //const products = parseProductsFromOrder(e);

                        let totalCuts = 0;

                        let cuts = (
                          <span style={{fontSize:11}}>
                            { e.cuts.map((c) => {
                              if (c.type !== 1) return null;
                              totalCuts += c.cut;
                              return <p key={c.idx}><b>{formatCurrency(c.cut)}</b> - {getNetworkById(c.entity_id) ? getNetworkById(c.entity_id).name : getTheaterById(c.entity_id).name}</p>;
                            })}
                          </span>
                        );

                        let advertiserName = '';
                        if (e.advertiser_name) advertiserName = e.advertiser_name;
                        else advertiserName = JSON.parse(e.additional_data) && JSON.parse(e.additional_data).advertiser_name ? JSON.parse(e.additional_data).advertiser_name.toUpperCase() : '?';
                        if (e.advertiser_identity) advertiserName += ' - ' + e.advertiser_identity;

                        return (
                          <Table.Row key={e.id}>
                            <Table.Cell textAlign='center'>{ e.type === 1 ? <a href={`/pedidos/${e.id}`} target='_blank' rel='noopener noreferrer'>#{e.id}</a> : `#${e.id}` } </Table.Cell>
                            <Table.Cell textAlign='center'>{ moment(e.date).format('DD/MM/YY') }</Table.Cell>
                            <Table.Cell>{ advertiserName }</Table.Cell>
                            <Table.Cell textAlign='center'>{ formatCurrency(e.total) }</Table.Cell>
                            <Table.Cell textAlign='center'><Popup trigger={<span>{ formatCurrency(totalCuts) }</span>} content={cuts} /></Table.Cell>
                            <Table.Cell textAlign='center'>{ e.type === 1 ? <a href={`/pedidos/${e.id}/PI`} target='_blank' rel='noopener noreferrer'><Button icon='eye' color='blue' size='mini' /></a> : <Button icon='eye' color='blue' size='mini' onClick={()=>this.createProposal(e)} /> }</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                }
              </div>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
