import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import './Movie.css';
export default class Movie extends Component {
  state = { width: 140, focused: false };
  timer = undefined;

  onMouseEnter() {
    this.setState({ focused: true });
  }

  onMouseLeave() {
    this.setState({ focused: false });
  }

  componentDidMount() {
    this.setState({ width: this.refs.movie ? this.refs.movie.clientWidth : this.state.width });

    this.timer = setInterval(() => {
      const width = this.refs.movie.clientWidth;
      if (this.state.width !== width) this.setState({ width: width });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let movie = { ...this.props.movie };
    movie.additional_data = JSON.parse(movie.additional_data);

    const height = this.state.width * 1.5;
    const fontSize = this.state.width * 0.085;

    return (
      <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={2} widescreen={2} className='Movie'>
        <figure ref='movie' style={{height:`${height}px`,backgroundImage:movie.additional_data.poster?`url(https://image.tmdb.org/t/p/w300${movie.additional_data.poster})`:'url(/imgs/no-poster.jpg)'}} onClick={() => { this.props.onClick(this) }} onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
          <div className='figurecaption'>
            <p style={{fontSize:`${fontSize}px`}}>{ !this.state.focused && movie.title}{ this.state.focused && 'DETALHES' }</p>
            <Icon name='chevron down' />
          </div>
        </figure>
        
      </Grid.Column>
    );
  }
}