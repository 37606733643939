import React, { Component } from 'react';

import { Grid, Message } from 'semantic-ui-react';
import ContentHeader from './../components/ContentHeader';

export default class NotFound extends Component {
  render() {
    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} path={['Erro', 'Não encontrado']}/>
        
        <Grid.Row className='content-margin'>
          <Grid.Column>
            <Message negative icon='warning sign'
              header='Página não encontrada'
              content='A página que você tentou acessar não existe ou está em manutenção.'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}